import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosClient } from "../../utils/axiosClient";

export const getShopsCategory = createAsyncThunk("category/", async () => {
  try {
    const response = await axiosClient.get("category/");
    return response.result;
  } catch (error) {
    return Promise.reject(error);
  }
});

export const getShopCustomer = createAsyncThunk(
  `shop/detailsForCustomer/id`,
  async (id) => {
    try {
      const response = await axiosClient.get(`shop/detailsForCustomer/${id}`);
      return response.result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getShopAdmin = createAsyncThunk(
  `shop/detailsForAdmin/id`,
  async (id) => {
    try {
      const response = await axiosClient.get(`shop/detailsForAdmin/${id}`);
      return response.result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

// reducers: {
//   addToCart: (state, action) => {
//     const curProduct = action.payload.item || action.payload;

//     const index = state.cart.findIndex(
//       (item) => item.product._id === curProduct.product._id
//     );
//     if (index === -1) {
//       state.cart.push({ ...curProduct });
//     } else {
//       if (curProduct.quantity === state.cart[index].quantity) {
//         state.cart[index].quantity += 1;
//       } else if (
//         curProduct.quantity > state.cart[index].quantity ||
//         curProduct.quantity < state.cart[index].quantity
//       ) {
//         state.cart[index].quantity = curProduct.quantity;
//       }
//     }
//   },
//   removeFromCart: (state, action) => {
//     const curKey = action.payload;

//     const index = state.cart.findIndex((item) => item.product._id === curKey);
//     if (index === -1) return;
//     if (state.cart[index].quantity === 1) {
//       state.cart = state.cart.filter((item) => item.product._id !== curKey);
//     } else {
//       state.cart[index].quantity -= 1;
//     }
//   },
//   deleteFromCart: (state, action) => {
//     const curKey = action.payload;

//     const index = state.cart.findIndex((item) => item.product._id === curKey);
//     if (index === -1) return;
//     else {
//       state.cart = state.cart.filter((item) => item.product._id !== curKey);
//     }
//   },
//   resetCart: (state) => {
//     state.cart = [];
//   },
// },

export const getAllAppointments = createAsyncThunk("appointment/", async () => {
  try {
    const response = await axiosClient.get("appointment/");
    return response.result;
  } catch (error) {
    return Promise.reject(error);
  }
});

const shopsSlice = createSlice({
  name: "shopsSlice",
  initialState: {
    categoriesData: [],
    shopsData: [],
    shopId: null,
    appointmentsData: [],
  },

  extraReducers: (builder) => {
    builder
      .addCase(getShopsCategory.fulfilled, (state, action) => {
        state.categoriesData = action.payload;
      })
      .addCase(getShopAdmin.fulfilled, (state, action) => {
        state.shopsData = action.payload;
        state.shopId = action.payload._id;
      })
      .addCase(getShopCustomer.fulfilled, (state, action) => {
        state.shopsData = action.payload;
        state.shopId = action.payload._id;
      })
      .addCase(getAllAppointments.fulfilled, (state, action) => {
        state.appointmentsData = action.payload;
      });
  },
});

export default shopsSlice.reducer;
