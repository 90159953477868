import React from "react";

function PrivacyPolicy() {
  const privacyPolicy = [
    {
      heading: "1. Introduction",
      description:
        'We at Book And Buy are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and protect your information when you use our website, <a href="https://bookandbuy.in" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:underline">https://bookandbuy.in</a>, and services related to booking appointments and placing orders.',
    },
    {
      heading: "2. Information We Collect",
      description: "We collect various types of information when you use our website:",
      list: [
        {
          heading: "Personal Information:",
          description: "Name, email address, and phone number.",
        },
        {
          heading: "Account Information:",
          description: "Details related to your login/signup process, including usernames, encrypted passwords, and any linked account (e.g., Google sign-in).",
        },
        {
          heading: "Shop Registration Data:",
          description: "Information provided when you register a shop on our platform, including shop name, category, location, contact information, and payment details when you register a shop.",
        },
        {
          heading: "Geolocation Data:",
          description: "Location-based data for shops using our service, for users who consent to location sharing.",
        },
        {
          heading: "Technical Data:",
          description: "IP address, browser type, and operating system details for analytics and security purposes.",
        },
      ],
    },
    {
      heading: "3. How We Use Your Information",
      description: "We use the collected information to:",
      list: [
        {
          description: "Provide our services (appointment bookings and order placements).",
        },
        {
          description: "Improve and optimize the website.",
        },
        {
          description: "Process payments securely.",
        },
        {
          description: "Communicate with users (order confirmations, updates, support).",
        },
        {
          description: "Display personalized recommendations based on user preferences and shop registration data.",
        },
        {
          description: "Enhance security and prevent fraud.",
        },
      ],
    },
    {
      heading: "4. Cookies and Tracking Technologies",
      description:
        "Our website uses cookies and similar technologies to track user activity and improve your experience. You can control cookies through your browser settings.",
    },
    {
      heading: "5. Data Sharing",
      description: "We do not share your personal data with third parties except:",
      list: [
        {
          heading: "Service Providers:",
          description: "Payment processors and other necessary service providers who assist us in operating the website.",
        },
        {
          heading: "Legal Obligations:",
          description: "When required by law or to protect the security of our users and systems.",
        },
      ],
    },
    {
      heading: "6. Data Security",
      description:
        "We implement security measures, such as encryption and regular audits, to protect your data. However, no method of data transmission or storage is entirely secure, and we cannot guarantee absolute security.",
    },
    {
      heading: "7. Your Data Rights",
      description: "You have the following rights regarding your personal information:",
      list: [
        {
          heading: "Access:",
          description: "Request a copy of the personal data we hold about you.",
        },
        {
          heading: "Correction:",
          description: "Request correction of incorrect or incomplete information.",
        },
        {
          heading: "Deletion:",
          description: "Request the deletion of your data, subject to any legal obligations.",
        },
        {
          heading: "Withdraw Consent:",
          description: "If you have given consent for data processing, you can withdraw it at any time.",
        },
      ],
    },
    {
      heading: "8. Third-Party Links",
      description:
        "Our website may contain links to third-party sites. We are not responsible for the privacy practices of those websites.",
    },
    {
      heading: "9. Children's Privacy",
      description:
        "Our services are not directed to children under Section 13, and we do not knowingly collect personal information from children.",
    },
    {
      heading: "10. Changes to This Privacy Policy",
      description:
        "We may update this Privacy Policy occasionally. Changes will be posted on this page, and we encourage you to review it regularly.",
    },
    {
      heading: "11. Contact Us",
      description:
        'If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:dhruvgarg@bookandbuy.in" class="text-blue-500 hover:underline">dhruvgarg@bookandbuy.in</a>.',
    },
  ];

  return (
    <div className="flex flex-col items-center mt-0 sm:-mt-5 md:mt-0 bg-white space-y-2 py-5">
      <p className="text-lg sm:text-xl font-semibold">
        Privacy Policy for Book And Buy
      </p>
      <p className="text-sm sm:text-base font-medium px-5 sm:px-0">
        Please read below Privacy Policy carefully before using this website:-
      </p>
      <div className="space-y-5">
        {privacyPolicy.map((policy, idx) => (
          <div
            key={idx}
            className="list-disc mx-10 sm:mx-20 md:mx-30 lg:mx-40 text-xs sm:text-sm space-y-2"
          >
            <div className="font-semibold text-lg">{policy.heading}</div>
            <div
              className="text-sm"
              dangerouslySetInnerHTML={{ __html: policy.description }}
            />
            {policy.list && (
              <ul className="list-disc ml-5">
                {policy.list.map((item, index) => (
                  <li key={index}>
                    <strong>{item.heading}</strong>
                    <span className="ml-2">{item.description}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default PrivacyPolicy;
