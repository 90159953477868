import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { axiosClient } from "../utils/axiosClient";
import { KEY_ACCESS_TOKEN, setItem } from "../utils/localStorageManager";
import loginImg from "../assets/signLogin.jpeg";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import { FcGoogle } from "react-icons/fc";
import toast from "react-hot-toast";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [togglePassword, setTogglePasword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setError("email");
      return toast.error("Please enter a valid email address.");
    }
    try {
      const response = await axiosClient.post("jwtAuth/login", {
        email,
        password,
      });
      setItem(KEY_ACCESS_TOKEN, response.result.accessToken);
      navigate("/");
    } catch (error) {
      return;
    }
  }

  const handleGoogleLogin = () => {
    window.open("https://api.bookandbuy.in/auth/google/callback", "_self");
  };

  return (
    <div className="flex justify-center bg-white p-5 sm:py-5">
      <Helmet>
        <title>Login - Book And Buy</title>
        <meta
          name="description"
          content="Log in to your Book And Buy account to book appointments and place orders with ease."
        />
      </Helmet>

      <div className="flex items-center p-5 sm:bg-white rounded sm:rounded-lg border border-blue-900 my-20 sm:my-0">
        <div className="sm:flex-1 hidden sm:block">
          <img
            src={loginImg}
            alt="User login illustration"
            className="size-96"
          />
        </div>
        <div className="flex-1 flex flex-col gap-3 text-xs sm:text-sm">
          <p className="text-lg sm:text-xl font-medium">Login</p>
          <form onSubmit={handleSubmit} className="flex flex-col gap-3">
            <input
              type="email"
              className={`${email ? "border-green-500" : "border-blue-900"} ${
                error === "email" && "border-red-500"
              } px-5 py-1 border border-blue-900 rounded focus:outline-none`}
              placeholder="Email"
              onChange={(e) => {
                setError("");
                setEmail(e.target.value);
              }}
            />

            <div
              className={`${
                password ? "border-green-500" : "border-blue-900"
              } flex items-center justify-between px-5 py-1 border rounded`}
            >
              <input
                type={togglePassword ? `text` : `password`}
                value={password}
                className=" focus:outline-none"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <p
                className="cursor-pointer"
                onClick={() => setTogglePasword(!togglePassword)}
              >
                {togglePassword ? <FaRegEye /> : <FaRegEyeSlash />}
              </p>
            </div>

            <input
              type="submit"
              className="px-5 py-1 text-white bg-blue-500 rounded focus:outline-none cursor-pointer"
            />
          </form>
          <p>
            Do not have an account?{" "}
            <Link to="/signup" className="text-blue-900 underline">
              Sign Up
            </Link>
          </p>
          <button
            onClick={handleGoogleLogin}
            className="flex items-center justify-center gap-2 border border-black rounded px-5 py-1 font-medium"
          >
            <FcGoogle className="text-2xl" /> Google
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
