import React from "react";

const ConfirmationModal = ({
  show,
  onClose,
  onConfirm,
  message,
  appointmentAction,
  action,
}) => {
  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-md text-center">
        {action !== "Update" && (
          <h2 className="mb-4 text-xl font-bold">Are you sure?</h2>
        )}
        <p className="mb-4">{message}</p>
        <div className="flex justify-center space-x-4">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
            onClick={onClose}
          >
            {!appointmentAction ? <p>Cancel</p> : <p>{appointmentAction}</p>}
          </button>
          <button
            className={`${
              action !== "Update"
                ? "bg-red-500 hover:bg-red-600"
                : "bg-green-500 hover:bg-green-600"
            } text-white px-4 py-2 rounded `}
            onClick={onConfirm}
          >
            {action}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
