import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosClient } from "../../utils/axiosClient";
import { getItem, KEY_ACCESS_TOKEN } from "../../utils/localStorageManager";

export const getMyInfo = createAsyncThunk("user/getMyInfo", async () => {
  try {
    const user = getItem(KEY_ACCESS_TOKEN);

    if (user) {
      const response = await axiosClient.get("user/getMyInfo");
      return response.result;
    } else {
      return null;
    }
  } catch (error) {
    return Promise.reject(error);
  }
});

const appConfigSlice = createSlice({
  name: "appConfigSlice",
  initialState: {
    isLoading: false,
    toastData: {},
    myProfile: null,
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    showToast: (state, action) => {
      state.toastData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyInfo.fulfilled, (state, action) => {
      state.myProfile = action.payload;
    });
  },
});

export default appConfigSlice.reducer;

export const { setLoading, showToast } = appConfigSlice.actions;
