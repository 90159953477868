import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useSelector } from "react-redux";

const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const shopId = useSelector((state) => state.shopsSlice.shopId);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className="inline-flex justify-center items-center w-full rounded-md border border-white shadow-sm px-1.5 py-0.5 sm:px-4 sm:py-2 bg-blue-900 text-xs sm:text-sm font-medium text-white focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          Add List
          <span
            className={`ml-2 transform ${
              isOpen ? "rotate-180" : "rotate-0"
            } text-2xl`}
          >
            <RiArrowDropDownLine />
          </span>
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute sm:right-0 mt-2 w-56 rounded-md shadow-lg bg-blue-900 text-white">
          <Link
            to={`/create-shop-features/${shopId}`}
            className="block px-4 py-2 text-sm hover:bg-blue-800 hover:text-gray-100 rounded-t-md"
          >
            Add Facility List
          </Link>
          <Link
            to={`/create-shop-products/${shopId}`}
            className="block px-4 py-2 text-sm hover:bg-blue-800 hover:text-gray-100 rounded-b-md"
          >
            Add Products List
          </Link>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
