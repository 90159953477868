import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosClient } from "../utils/axiosClient";
import { BiSolidCameraPlus } from "react-icons/bi";
import toast from "react-hot-toast";

const initialInputs = {
  shopName: "",
  shopType: "order",
  email: "",
  phone: "",
  shopAddress: "",
  shopDetail: "",
  fromTime: "",
  toTime: "",
  shopCategory: "grocery shop",
  operatingDays: {
    M: false,
    T: false,
    W: false,
    Th: false,
    F: false,
    Sa: false,
    Su: false,
  },
  lat: "",
  lng: "",
};

function CreateShop() {
  const [shopImg, setShopImg] = useState("");
  const [inputs, setInputs] = useState(initialInputs);
  const [allShopCategories, setAllShopCategories] = useState([]);
  const [location, setLocation] = useState({ lat: null, lng: null });
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchAllCategoryShop();
    if (navigator.geolocation) {
      // Request the current position
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // On success, set the coordinates
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (err) => {
          console.log(err.message);
        },
        {
          enableHighAccuracy: true,
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  const fetchAllCategoryShop = async () => {
    try {
      const response = await axiosClient.get("category/");
      setAllShopCategories(response.result);
    } catch (error) {
      return;
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      if (fileReader.readyState === fileReader.DONE) {
        setShopImg(fileReader.result);
        setError("");
      }
    };
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (e.target.type === "checkbox") {
      setInputs((prevInputs) => ({
        ...prevInputs,
        operatingDays: {
          ...prevInputs.operatingDays,
          [name]: value,
        },
      }));
    } else {
      setInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }

    setError("");
  };

  const validateLatitude = (lat) => {
    const latitude = parseFloat(lat);
    return !isNaN(latitude) && latitude >= -90 && latitude <= 90;
  };

  const validateLongitude = (lng) => {
    const longitude = parseFloat(lng);
    return !isNaN(longitude) && longitude >= -180 && longitude <= 180;
  };

  function validateContactInfo(phone, email) {
    const isPhoneValid = /^\d{10}$/.test(phone);
    const isEmailValid = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.test(email);

    return {
      phoneValid: isPhoneValid,
      emailValid: isEmailValid,
    };
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!shopImg) {
      setError("shopImg");
      return toast.error("Shop image is required");
    }

    if (!inputs.shopName) {
      setError("shopName");
      return toast.error("Shop name is required");
    }

    if (!inputs.shopType) {
      setError("shopType");
      return toast.error("Shop type is required");
    }

    if (!inputs.email) {
      setError("email");
      return toast.error("Email is required");
    }

    if (!inputs.phone) {
      setError("phone");
      return toast.error("Phone Number is required");
    }

    const contactInfo = validateContactInfo(inputs.phone, inputs.email);

    if (!contactInfo.emailValid) {
      setError("email");
      return toast.error("Invalid email address.");
    }

    if (!contactInfo.phoneValid) {
      setError("phone");
      return toast.error("Phone Number must be of 10 digits.");
    }

    if (!inputs.shopAddress) {
      setError("shopAddress");
      return toast.error("Shop address is required");
    }

    if (!inputs.shopDetail) {
      setError("shopDetail");
      return toast.error("Shop Detail is required");
    }

    if (!inputs.shopCategory) {
      setError("shopCategory");
      return toast.error("Shop Category is required");
    }

    if (!inputs.fromTime) {
      setError("fromTime");
      return toast.error("Opening time is required");
    }

    if (!inputs.toTime) {
      setError("toTime");
      return toast.error("Closing time is required");
    }

    if (!inputs.lat && !location.lat) {
      setError("lat");
      return toast.error("Location Latitude is required.");
    }

    if (!inputs.lng && !location.lng) {
      setError("lng");
      return toast.error("Location Longitude is required");
    }

    // Validate latitude and longitude
    if (!validateLatitude(inputs.lat || location.lat)) {
      setError("lat");
      return toast.error("Invalid Latitude");
    }

    if (!validateLongitude(inputs.lng || location.lng)) {
      setError("lng");
      return toast.error("Invalid Longitude");
    }

    const operatingDays = Object.keys(inputs.operatingDays).filter(
      (day) => inputs.operatingDays[day]
    );

    if (!(Array.isArray(operatingDays) && operatingDays.length > 0)) {
      setError("operatingDays");
      return toast.error("Opening Days are required");
    }

    try {
      const shopData = {
        shopImg: shopImg,
        shopName: inputs.shopName,
        shopDetail: inputs.shopDetail,
        fromTime: inputs.fromTime,
        toTime: inputs.toTime,
        type: inputs.shopType,
        shopAddress: inputs.shopAddress,
        phone: inputs.phone,
        email: inputs.email,
        operatingDays,
        category: inputs.shopCategory,
        lat: inputs.lat || location.lat,
        lng: inputs.lng || location.lng,
      };

      // Store in localStorage
      localStorage.setItem("shopData", JSON.stringify(shopData));

      navigate("/payment");
    } catch (error) {
      if (Array.isArray(error)) {
        error.forEach((e) => toast.error(e));
      }
      return;
    }
  };

  const imgLabel =
    "flex justify-center items-center border-2 rounded-xl w-full h-52 text-blue-900 text-2xl border-blue-900 border-dashed";
  const input =
    "border-2 rounded-full px-2 text-sm py-[1px] w-full focus:outline-none";
  const label = "font-medium";
  const submit =
    "bg-blue-900 rounded-full px-2 text-white w-full cursor-pointer font-semibold mt-5 sm:mt-0 hover:bg-blue-800 hover:text-gray-100";

  return (
    <div className="mb-10 mt-1">
      <p className="bg-blue-900 text-white font-bold text-2xl text-center mb-5 py-1">
        Register Your Business
      </p>
      <div className="flex justify-center">
        <form
          className="w-full sm:w-auto mx-5 border-2 border-blue-900 rounded-xl py-5 px-10 shadow-2xl shadow-blue-200"
          onSubmit={handleSubmit}
        >
          {/* IMAGE */}
          <div className="flex justify-center mb-5">
            {shopImg ? (
              <div className="w-full">
                <img
                  src={shopImg}
                  alt="userImg"
                  className="border-2 border-green-500 border-dashed rounded-xl w-full h-52"
                />
              </div>
            ) : (
              <div
                className={`${imgLabel} ${
                  error === "shopImg" && "border-red-500 text-red-500"
                }`}
              >
                <label htmlFor="inputImg">
                  <BiSolidCameraPlus className="cursor-pointer" />
                </label>
                <input
                  className={`${input} hidden`}
                  id="inputImg"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
            )}
          </div>

          <div className="sm:flex justify-between items-center">
            <div>
              <p className={`${label}`}>Shop Name:</p>
              <input
                className={`${input} ${
                  inputs.shopName ? "border-green-500" : "border-blue-900"
                } ${error === "shopName" && "border-red-500"}`}
                type="text"
                name="shopName"
                value={inputs.shopName || ""}
                onChange={handleChange}
              />
            </div>
            <div className="my-5 sm:my-0">
              <p className={`${label}`}>Shop Type:</p>
              <select
                className={`${input} ${
                  inputs.shopType ? "border-green-500" : "border-blue-900"
                } ${error === "shopType" && "border-red-500"} sm:w-56`}
                name="shopType"
                value={inputs.shopType || ""}
                onChange={handleChange}
              >
                <option value="order">Order</option>
                <option value="appointment">Appointment</option>
              </select>
            </div>
          </div>

          <div className="sm:flex gap-20 items-center my-5">
            <div>
              <p className={`${label}`}>Email:</p>
              <input
                className={`${input} ${
                  inputs.email ? "border-green-500" : "border-blue-900"
                } ${error === "email" && "border-red-500"}`}
                type="email"
                name="email"
                value={inputs.email || ""}
                onChange={handleChange}
              />
            </div>
            <div className="my-5 sm:my-0">
              <p className={`${label}`}>Phone:</p>
              <input
                className={`${input} ${
                  inputs.phone ? "border-green-500" : "border-blue-900"
                } ${error === "phone" && "border-red-500"}`}
                type="text"
                name="phone"
                value={inputs.phone || ""}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="my-5">
            <p className={`${label}`}>Shop Address:</p>
            <input
              type="text"
              className={`${input} ${
                inputs.shopAddress ? "border-green-500" : "border-blue-900"
              } ${error === "shopAddress" && "border-red-500"}`}
              name="shopAddress"
              value={inputs.shopAddress || ""}
              onChange={handleChange}
            />
          </div>

          <div className="my-5">
            <p className={`${label}`}>Shop Detail:</p>
            <textarea
              name="shopDetail"
              value={inputs.shopDetail || ""}
              onChange={handleChange}
              className={`border-2 w-full rounded-lg h-36 p-2 focus:outline-none ${
                inputs.shopDetail ? "border-green-500" : "border-blue-900"
              } ${error === "shopDetail" && "border-red-500"}`}
            />
          </div>

          <div className="sm:flex justify-between my-5">
            <div className="sm:w-52 ">
              <p className={`${label}`}>Shop Variety</p>
              <select
                className={`${input} ${
                  inputs.shopCategory ? "border-green-500" : "border-blue-900"
                } ${error === "shopCategory" && "border-red-500"} `}
                name="shopCategory"
                value={inputs.shopCategory || ""}
                onChange={handleChange}
              >
                {allShopCategories.map((data) => (
                  <option key={data._id} value={data.categoryName}>
                    {data.categoryName}
                  </option>
                ))}
              </select>
            </div>

            <div className="my-5 sm:my-0">
              <p className={`${label} mb-1`}>Operating Hours:</p>
              <div className="flex gap-2">
                <label htmlFor="fromTime" className={`${label}`}>
                  From
                  <input
                    type="time"
                    name="fromTime"
                    id="fromTime"
                    value={inputs.fromTime}
                    onChange={handleChange}
                    className={`${input} ${
                      inputs.fromTime ? "border-green-500" : "border-blue-900"
                    } ${error === "fromTime" && "border-red-500"}`}
                  />
                </label>

                <label htmlFor="toTime" className={`${label}`}>
                  To
                  <input
                    type="time"
                    name="toTime"
                    id="toTime"
                    value={inputs.toTime}
                    onChange={handleChange}
                    className={`${input} ${
                      inputs.toTime ? "border-green-500" : "border-blue-900"
                    } ${error === "toTime" && "border-red-500"}`}
                  />
                </label>
              </div>
            </div>
          </div>

          <div className="my-5">
            <p className={`${label} mb-1`}>Location:</p>
            <div className="sm:flex justify-between">
              <div>
                <p className={`${label}`}>Longitude:</p>
                <input
                  type="text"
                  className={`${input} ${
                    inputs.lng ? "border-green-500" : "border-blue-900"
                  } ${error === "lng" && "border-red-500"}`}
                  name="lng"
                  value={inputs.lng || location.lng}
                  onChange={handleChange}
                />
              </div>
              <div className="my-5 sm:my-0">
                <p className={`${label}`}>Latitude:</p>
                <input
                  type="text"
                  className={`${input} ${
                    inputs.lat ? "border-green-500" : "border-blue-900"
                  } ${error === "lat" && "border-red-500"}`}
                  name="lat"
                  value={inputs.lat || location.lat}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <p className="text-xs">
            <span className="text-red-500">NOTE:</span> Latitude and Longitude
            should be accurate for your shop's location.
          </p>

          <div className="my-5">
            <p className={`${label}`}>Opening Days:</p>
            <div className="flex gap-2">
              {["M", "T", "W", "Th", "F", "Sa", "Su"].map((day) => (
                <div key={day}>
                  <input
                    type="checkbox"
                    name={day}
                    checked={inputs.operatingDays[day]}
                    onChange={handleChange}
                  />{" "}
                  {day}
                </div>
              ))}
            </div>
          </div>

          <input
            type="submit"
            value="Save & Continue"
            className={`${submit}`}
          />
        </form>
      </div>
    </div>
  );
}

export default CreateShop;
