import React, { useEffect, useState } from "react";
import { axiosClient } from "../utils/axiosClient";
import { PiMaskSad } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

function AllOrders() {
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await axiosClient.get("orders/");
      setOrders(response.result);
    } catch (e) {
      return;
    }
  };

  return (
    <div className="flex justify-center">
      <div>
        <p className="text-center text-blue-900 py-1 text-lg sm:text-2xl">
          Your Orders
        </p>
        {orders.length === 0 ? (
          <div className="text-blue-900 flex flex-col gap-5 mt-2 mb-5 h-screen sm:h-96">
            <p className="flex justify-center text-8xl">
              <PiMaskSad />
            </p>
            <p className="text-center font-semibold">No Orders Yet!</p>
          </div>
        ) : (
          <div className="overflow-y-auto hideScrollbar">
            <ul className="flex flex-col h-96 gap-2 mt-2 mb-5">
              {orders.map((item, idx) => (
                <li
                  className="flex justify-around items-center w-56 sm:w-96 border border-blue-900 py-1 rounded transition-transform transform hover:scale-95"
                  key={idx}
                >
                  <div>
                    <img
                      src={item.productId.imgUrl.url}
                      alt={item.productId.name}
                      className="rounded border border-blue-900 size-12 sm:size-16"
                    />
                  </div>
                  <div className="w-28 sm:w-40 text-xs sm:text-sm">
                    <p className="font-medium">{item.productId.name}</p>
                    <div className="flex justify-between items-center">
                      <p>
                        Qty:{" "}
                        <span className="font-medium">
                          {item.productQuantity}
                        </span>
                      </p>
                      <p>
                        ₹:{" "}
                        <span className="font-medium">
                          {item.productId.price}
                        </span>
                      </p>
                    </div>
                    <p
                      onClick={() =>
                        navigate(`/shop-detail/${item.shopId._id}`)
                      }
                      className="cursor-pointer"
                    >
                      By:{" "}
                      <span className="font-medium">
                        {item.shopId.shopName}
                      </span>
                    </p>
                    <p
                      className={`${
                        item.orderStatus === "Pending" && "text-yellow-500"
                      } 
                        ${item.orderStatus === "Packed" && "text-blue-500 "} 
                        ${
                          item.orderStatus === "Delivered" && "text-green-500"
                        } `}
                    >
                      {item.orderStatus}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default AllOrders;
