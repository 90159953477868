import React, { useState, useRef, useEffect } from "react";

const Tooltip = ({ children, tooltipText }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState("center");
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const { left, right } = tooltipRef.current.getBoundingClientRect();
      const screenWidth = window.innerWidth;

      if (right > screenWidth) {
        setTooltipPosition("left");
      } else if (left < 0) {
        setTooltipPosition("right");
      } else {
        setTooltipPosition("center");
      }
    }
  }, [showTooltip]);

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        className="inline-block cursor-pointer"
      >
        {children}
      </div>
      {showTooltip && (
        <div
          ref={tooltipRef}
          className={`absolute ${tooltipPositionClasses[tooltipPosition]} top-full w-max p-2 bg-white text-blue-900 text-sm rounded shadow-lg`}
        >
          {tooltipText}
        </div>
      )}
    </div>
  );
};

const tooltipPositionClasses = {
  center: "left-1/2 transform -translate-x-1/2",
  left: "right-0 transform translate-x-0",
  right: "left-0 transform translate-x-0",
};

export default Tooltip;
