import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosClient } from "../../utils/axiosClient";
import { getItem, KEY_ACCESS_TOKEN } from "../../utils/localStorageManager";

export const getCart = createAsyncThunk("cart/", async () => {
  try {
    const user = getItem(KEY_ACCESS_TOKEN);

    if (user) {
      const response = await axiosClient.get("cart/");

      return response.result;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
});

export const addToCart = createAsyncThunk(
  "cart/addToCart",
  async ({ productId, quantity, shopId }) => {
    try {
      const response = await axiosClient.post("cart/addToCart", {
        productId,
        quantity,
        shopId,
      });
      return response.result;
    } catch (error) {
      return [];
    }
  }
);

export const removeFromCart = createAsyncThunk(
  "cart/removeFromCart",
  async (productId) => {
    try {
      const response = await axiosClient.put("cart/removeFromCart", {
        productId,
      });

      return response.result;
    } catch (error) {
      return [];
    }
  }
);

export const deleteFromCart = createAsyncThunk(
  "cart/deleteFromCart",
  async (productId) => {
    try {
      const response = await axiosClient.delete(
        `cart/deleteFromCart/${productId}`
      );

      return response.result;
    } catch (error) {
      return [];
    }
  }
);

export const resetCart = createAsyncThunk("cart/resetCart", async () => {
  try {
    const response = await axiosClient.delete("cart/resetCart");

    return response.result;
  } catch (error) {
    return [];
  }
});

const cartSlice = createSlice({
  name: "cartSlice",
  initialState: {
    cart: [],
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCart.fulfilled, (state, action) => {
        state.cart = action.payload;
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.cart = action.payload;
      })
      .addCase(removeFromCart.fulfilled, (state, action) => {
        state.cart = action.payload;
      })
      .addCase(deleteFromCart.fulfilled, (state, action) => {
        state.cart = action.payload;
      })
      .addCase(resetCart.fulfilled, (state, action) => {
        state.cart = action.payload;
      });
  },
});

export default cartSlice.reducer;
