export const isShopOpen = (operatingDays, operatingHours) => {
  const dayMap = {
    Sun: "Su",
    Mon: "M",
    Tue: "T",
    Wed: "W",
    Thu: "Th",
    Fri: "F",
    Sat: "Sa",
  };

  const today = new Date().toLocaleString("en-US", { weekday: "short" });
  const currentDay = dayMap[today];

  // Get the current time in HH:mm format (24-hour format)
  const currentTime = new Date().toTimeString().slice(0, 5);

  // If today is not an operating day, the shop is closed
  if (!operatingDays?.includes(currentDay)) {
    return false;
  }

  // Convert operating hours from "10:00 AM - 07:00 PM" to 24-hour format
  const convertTo24Hour = (timeStr) => {
    const [time, modifier] = timeStr.split(" ");
    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = modifier === "AM" ? "00" : "12";
    } else if (modifier === "PM") {
      hours = String(parseInt(hours, 10) + 12);
    }

    return `${hours.padStart(2, "0")}:${minutes}`;
  };

  const [openTime, closeTime] = operatingHours
    .split(" - ")
    .map(convertTo24Hour);

  // Check if the current time is within the shop's operating hours
  return currentTime >= openTime && currentTime <= closeTime;
};
