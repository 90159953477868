import React, { useEffect } from "react";
import { Helmet } from "react-helmet"; // Ensure Helmet is imported
import Navbar from "../components/Navbar";
import { useDispatch } from "react-redux";
import { getMyInfo } from "../redux/slices/appConfigSlice";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import { getCart } from "../redux/slices/cartSlice";

function Home() {
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      dispatch(getMyInfo());
      dispatch(getCart());
    } catch (error) {
      return;
    }
  }, [dispatch]);

  return (
    <>
      <Helmet>
        {/* Title of the page */}
        <title>Home - Book Appointments & Place Orders | Book And Buy</title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Easily book appointments and place orders with the nearest shops. Find services, schedule appointments, and manage your orders all in one place."
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="book appointments, place orders, nearest shops, local services, schedule appointments, manage orders, local shops"
        />

        {/* Open Graph Meta Tags for Social Sharing */}
        <meta
          property="og:title"
          content="Book Appointments & Place Orders | Book And Buy"
        />
        <meta
          property="og:description"
          content="Easily book appointments and place orders with the nearest shops. Find services, schedule appointments, and manage your orders all in one place."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_SERVER_BASE_URL}`}
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dlda7p9ne/image/upload/v1727783996/BookAndBuyLogo_ohju7p.jpg"
        />
        <meta property="og:site_name" content="Book And Buy" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Book Appointments & Place Orders" />
        <meta
          name="twitter:description"
          content="Easily book appointments and place orders with the nearest shops. Find services, schedule appointments, and manage your orders all in one place."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/dlda7p9ne/image/upload/v1727783996/BookAndBuyLogo_ohju7p.jpg"
        />
        <meta name="twitter:site" content="@bookandbuy" />

        {/* Canonical Link for SEO */}
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_SERVER_BASE_URL}`}
        />
      </Helmet>

      {/* Body Components */}
      <Navbar />
      <div className="pt-11 sm:pt-16">
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

export default Home;
