import React, { useEffect, useState } from "react";
import { IoStarSharp } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { axiosClient } from "../utils/axiosClient";
import Products from "../components/adminComponents/Products";
import Facilities from "../components/adminComponents/Facilities";
import { GrEdit } from "react-icons/gr";
import Tooltip from "../components/Tooltip";
import { MdOutlineDelete } from "react-icons/md";
import toast from "react-hot-toast";
import UpdateDetail from "../components/adminComponents/UpdateDetail";
import { IoIosAddCircleOutline } from "react-icons/io";
import AddShopFacilities from "../components/adminComponents/AddShopFacilities";
import AddShopProducts from "../components/adminComponents/AddShopProducts";
import ConfirmationModal from "../components/ConfirmationModal";
import Dropdown from "../components/adminComponents/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { getShopAdmin } from "../redux/slices/shopsSlice";
import { RiSecurePaymentLine } from "react-icons/ri";

function ShopAdmin() {
  const params = useParams();
  const shopId = params.id;
  const navigate = useNavigate();

  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [toggleAddFacility, setToggleAddFacility] = useState(false);
  const [toggleAddProduct, setToggleAddProduct] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isPaymentDone, setIsPaymentDone] = useState(false);

  const [id, setId] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [itemType, setItemType] = useState("");
  const [deleteItemName, setDeleteItemName] = useState("");
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();

  const data = useSelector((state) => state?.shopsSlice?.shopsData);

  useEffect(() => {
    try {
      const checkPaymentStatus = async () => {
        try {
          setLoad(true);
          const response = await axiosClient.get(`payment/status/${shopId}`);
          setIsPaymentDone(response.result.isPaid);
        } catch (e) {
          return;
        } finally {
          setLoad(false);
        }
      };
      dispatch(getShopAdmin(`${params.id}`));
      checkPaymentStatus();
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, shopId]);

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProductId(null);
    setItemType("");
    setDeleteItemName("");
  };

  const handleDeleteClick = (id, type, itemName) => {
    setSelectedProductId(id);
    setItemType(type);
    setDeleteItemName(itemName);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (itemType === "facilities") {
        const response = await axiosClient.delete(
          `deleteShop/shopFeatures/${shopId}/${selectedProductId}`
        );
        toast.success(response.result);
      }
      if (itemType === "products") {
        const response = await axiosClient.delete(
          `deleteShop/shopProducts/${shopId}/${selectedProductId}`
        );
        toast.success(response.result);
      }
      dispatch(getShopAdmin(`${shopId}`));
    } catch (error) {
      return;
    }
    handleCloseModal();
  };

  return (
    <>
      <div
        className={`relative min-h-screen ${
          !isPaymentDone && !load && "blur-md"
        }`}
      >
        <div className="flex items-center text-center bg-blue-900 text-white font-bold py-1 text-base sm:text-2xl">
          <div className="flex-1">
            <Dropdown />
          </div>
          <p className="flex-1">{data?.shopName}</p>
          <div className="flex-1 flex items-center justify-center gap-2 sm:gap-3">
            <Tooltip tooltipText="Edit">
              <GrEdit onClick={() => setToggleUpdate(true)} />
            </Tooltip>
            <Tooltip tooltipText="Payment History">
              <RiSecurePaymentLine
                onClick={() => navigate(`/shop-admin/${shopId}/makePayment`)}
              />
            </Tooltip>
          </div>
        </div>

        {/* SHOP DETAIL */}
        <div className="flex justify-center m-7 lg:m-10 text-xs sm:text-sm">
          <div className="flex flex-col md:flex-row gap-7 md:gap-20 lg:gap-32">
            <img
              src={data?.imgUrl?.url}
              alt="shop-img"
              className="flex-1 border border-blue-900 rounded sm:rounded-lg size-96"
            />

            <div className="flex-1 flex flex-col gap-5 sm:gap-7">
              <p>{data?.ShopOwner?.name}</p>
              <p>{data?.shopDetail}</p>
              <p>
                Location:{" "}
                <span className="font-medium">{data?.shopAddress}</span>
              </p>
              <p>
                Customer Support:{" "}
                <span className="font-medium">{data?.phone}</span>
              </p>
              <p>
                Email: <span className="font-medium">{data?.email}</span>
              </p>
              <p>
                Shopkeeper:{" "}
                <span className="font-medium">{data?.shopOwner?.name}</span>
              </p>
              <div>
                <p>
                  Operating Hours:{" "}
                  <span className="font-medium">{data?.operatingHours}</span>
                </p>
              </div>
              <div className="flex items-center justify-between">
                {data?.type === "appointment" && (
                  <div className="relative inline-block">
                    <button
                      className="border-2 rounded-full px-4 text-blue-900 border-blue-900 font-semibold cursor-pointer"
                      onClick={() => navigate(`/appointmentsList/${params.id}`)}
                    >
                      All Appointments
                    </button>

                    {data.appointments.length > 0 && (
                      <span className="absolute top-[-8px] right-[-8px] inline-flex items-center justify-center h-6 w-6 rounded-full bg-red-500 text-white text-xs font-bold">
                        {data.appointments.length}
                      </span>
                    )}
                  </div>
                )}

                {data?.type === "order" && (
                  <div className="relative inline-block">
                    <button
                      className="border-2 rounded-full px-4 text-blue-900 border-blue-900 font-semibold cursor-pointer"
                      onClick={() =>
                        navigate(`/shop-admin/${shopId}/allOrders`)
                      }
                    >
                      All Orders
                    </button>

                    {data.orders.length > 0 && (
                      <span className="absolute top-[-8px] right-[-8px] inline-flex items-center justify-center h-6 w-6 rounded-full bg-red-500 text-white text-xs font-bold">
                        {data.orders.length}
                      </span>
                    )}
                  </div>
                )}

                <p className="flex items-center gap-2 font-normal text-blue-900">
                  {data?.averageRating?.toFixed(1)}/5
                  <IoStarSharp />
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* FACILITIES */}
        {data?.features?.map((featuresData, idx) => (
          <div key={idx}>
            {/* FACILITY TYPE */}
            <div className="flex items-center text-center bg-blue-900 text-white font-bold py-1 text-sm sm:text-lg">
              <p className="flex-1 sm:text-xl">
                <Tooltip tooltipText="Add new facility">
                  <IoIosAddCircleOutline
                    onClick={() => (
                      setId(featuresData._id), setToggleAddFacility(true)
                    )}
                  />
                </Tooltip>
              </p>
              <p className="flex-1">{featuresData.feature}</p>
              <div className="flex-1 sm:text-xl">
                <Tooltip tooltipText="Delete List">
                  <MdOutlineDelete
                    onClick={() =>
                      handleDeleteClick(
                        featuresData._id,
                        "facilities",
                        featuresData.feature
                      )
                    }
                  />
                </Tooltip>
              </div>
            </div>

            <div className="flex justify-center items-center">
              <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 max-w-screen-lg p-4 sm:py-16">
                {featuresData.allFeatures.map((feature, key) => (
                  <Facilities
                    key={key}
                    facility={feature}
                    featuresId={featuresData._id}
                  />
                ))}
              </div>
            </div>
          </div>
        ))}

        {/* PRODUCTS */}
        {data?.products?.map((productsData, idx) => (
          <div key={idx}>
            {/* PRODUCT TYPE */}
            <div className="flex items-center text-center bg-blue-900 text-white font-bold py-1 text-sm sm:text-lg">
              <p className="flex-1 sm:text-xl">
                <Tooltip tooltipText="Add New Product">
                  <IoIosAddCircleOutline
                    onClick={() => (
                      setId(productsData._id), setToggleAddProduct(true)
                    )}
                  />
                </Tooltip>
              </p>
              <p className="flex-1 ">{productsData.product}</p>
              <div className="flex-1 sm:text-xl">
                <Tooltip tooltipText="Delete List">
                  <MdOutlineDelete
                    onClick={() =>
                      handleDeleteClick(
                        productsData._id,
                        "products",
                        productsData.product
                      )
                    }
                  />
                </Tooltip>
              </div>
            </div>

            <div className="flex justify-center items-center">
              <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 max-w-screen-lg p-4 sm:py-16">
                {productsData.allProducts.map((product, key) => (
                  <Products
                    product={product}
                    key={key}
                    productsId={productsData._id}
                  />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      {!isPaymentDone && !load && (
        <div className="fixed inset-0 flex item-center justify-center z-1">
          <div className="flex flex-col justify-center text-center text-black space-y-4 mx-5">
            <p className="text-2xl font-semibold">Payment Pending</p>
            <p>
              You need to make the payment for this month to access your shop
              admin features.
            </p>
            <button
              onClick={() => navigate(`/shop-admin/${shopId}/makePayment`)}
              className="px-6 py-2 bg-blue-500 text-white font-semibold rounded"
            >
              Make Payment
            </button>
          </div>
        </div>
      )}

      <ConfirmationModal
        show={showModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        message={
          itemType === "facilities"
            ? `Do you really want to delete ${deleteItemName} list? This action cannot be undone.`
            : `Do you really want to delete ${deleteItemName} list? This action cannot be undone.`
        }
        action="Delete"
      />

      {toggleUpdate && (
        <UpdateDetail data={data} onClose={() => setToggleUpdate(false)} />
      )}

      {toggleAddFacility && (
        <AddShopFacilities
          id={id}
          onClose={() => setToggleAddFacility(false)}
        />
      )}

      {toggleAddProduct && (
        <AddShopProducts id={id} onClose={() => setToggleAddProduct(false)} />
      )}
    </>
  );
}

export default ShopAdmin;
