import React, { useEffect, useState } from "react";
import { IoStarSharp } from "react-icons/io5";
import { useParams } from "react-router-dom";
import Products from "../components/Products";
import Facilities from "../components/Facilities";
import CreateAppointment from "../components/CreateAppointment";
import { getShopCustomer } from "../redux/slices/shopsSlice";
import { useDispatch, useSelector } from "react-redux";
import QueueStatus from "../components/QueueStatus";
import RatingModal from "../components/RatingModal";
import { FaMapMarkerAlt } from "react-icons/fa";
import { isShopOpen } from "../utils/shopStatus";

function ShopDetail() {
  const params = useParams();
  const [toggleAppointment, setToggleAppointment] = useState(false);
  const [toggleQueueStatus, setToggleQueueStatus] = useState(false);
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  const [location, setLocation] = useState({ lat: null, lng: null });

  const dispatch = useDispatch();
  const data = useSelector((state) => state.shopsSlice.shopsData);

  useEffect(() => {
    dispatch(getShopCustomer(`${params.id}`));
  }, [dispatch]);

  useEffect(() => {
    if (navigator.geolocation) {
      // Request the current position
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // On success, set the coordinates
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (err) => {
          console.log(err.message);
        },
        {
          enableHighAccuracy: true,
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  return (
    <>
      {data ? (
        <>
          <div className="relative min-h-screen">
            <p className="text-center bg-blue-900 text-white font-bold py-1 text-lg sm:text-2xl">
              {data?.shopName}
            </p>
            {/* SHOP DETAIL */}
            <div className="flex justify-center m-7 lg:m-10 text-xs sm:text-sm">
              <div className="flex flex-col md:flex-row gap-7 md:gap-20 lg:gap-32">
                <img
                  src={data?.imgUrl?.url}
                  alt="shop-img"
                  className="flex-1 border border-blue-900 rounded sm:rounded-lg size-96"
                />

                <div className="flex-1 flex flex-col gap-5 sm:gap-7">
                  <p>{data?.shopDetail}</p>
                  <p>
                    Location:{" "}
                    <span className="font-medium">{data?.shopAddress}</span>
                  </p>
                  <p>
                    Customer Support:{" "}
                    <span className="font-medium">{data?.phone}</span>
                  </p>
                  <p>
                    Email: <span className="font-medium">{data?.email}</span>
                  </p>
                  <p>
                    Shop Owner:{" "}
                    <span className="font-medium">{data?.shopOwner?.name}</span>
                  </p>
                  <div>
                    <p>
                      Operating Hours:{" "}
                      <span className="font-medium">
                        {data?.operatingHours}
                      </span>
                    </p>
                  </div>
                  <p
                    className={`font-medium rounded w-14 text-center p-[2px] text-white ${
                      isShopOpen(data?.operatingDays, data?.operatingHours)
                        ? "bg-green-500"
                        : "bg-red-500"
                    }`}
                  >
                    {isShopOpen(data?.operatingDays, data?.operatingHours)
                      ? "Open"
                      : "Closed"}
                  </p>
                  <div
                    className={`flex ${
                      data.type === "appointment"
                        ? "flex-col gap-7"
                        : "items-center justify-between"
                    }`}
                  >
                    {data.type === "appointment" && (
                      <div className="flex w-full justify-around items-center">
                        <button
                          className="border-2 rounded-full px-4 text-blue-900 border-blue-900 font-semibold cursor-pointer"
                          onClick={() => {
                            setToggleAppointment(true);
                          }}
                        >
                          Book Appointment
                        </button>
                        <button
                          className="border-2 rounded-full px-4 text-blue-900 border-blue-900 font-semibold cursor-pointer"
                          onClick={() => setToggleQueueStatus(true)}
                        >
                          Queue Status
                        </button>
                      </div>
                    )}

                    <div className="flex w-full justify-around items-center">
                      <p
                        className="flex items-center gap-2 font-normal text-blue-900 cursor-pointer"
                        onClick={() => {
                          setIsRatingModalOpen(true);
                        }}
                      >
                        {data?.averageRating?.toFixed(1)}/5
                        <IoStarSharp />
                      </p>

                      <a
                        href={`https://www.google.com/maps/dir/?api=1&origin=${location.lat},${location.lng}&destination=${data?.location?.coordinates[1]},${data?.location?.coordinates[0]}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex gap-2 items-center border-2 rounded-full px-4 text-blue-900 border-blue-900 font-semibold"
                      >
                        <FaMapMarkerAlt /> <span>Find on map</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* FACILITIES */}
            {data?.features?.map((featuresData, idx) => (
              <div key={idx}>
                {/* FACILITY TYPE */}
                <p className="text-center bg-blue-900 text-white font-bold py-1 text-sm sm:text-lg">
                  {featuresData.feature}
                </p>
                <div className="flex justify-center items-center">
                  <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 max-w-screen-lg p-4 sm:py-16">
                    {featuresData.allFeatures.map((feature, key) => (
                      <Facilities facility={feature} key={key} />
                    ))}
                  </div>
                </div>
              </div>
            ))}

            {/* PRODUCTS */}
            {data?.products?.map((productsData, idx) => (
              <div key={idx}>
                {/* PRODUCT TYPE */}
                <p className="text-center bg-blue-900 text-white font-bold py-1 text-lg">
                  {productsData.product}
                </p>

                <div className="flex justify-center items-center">
                  <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 max-w-screen-lg p-4 sm:py-16">
                    {productsData.allProducts.map((product, key) => (
                      <Products
                        product={product}
                        shopId={params.id}
                        key={key}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {toggleAppointment && (
            <CreateAppointment
              onClose={() => setToggleAppointment(false)}
              operatingHours={data.operatingHours}
            />
          )}

          {toggleQueueStatus && (
            <QueueStatus onClose={() => setToggleQueueStatus(false)} />
          )}

          {isRatingModalOpen && (
            <RatingModal
              onClose={() => setIsRatingModalOpen(false)}
              shopId={params.id}
            />
          )}
        </>
      ) : (
        <p>nothing</p>
      )}
    </>
  );
}

export default ShopDetail;
