import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { axiosClient } from "../utils/axiosClient";
import { KEY_ACCESS_TOKEN, setItem } from "../utils/localStorageManager";
import loginImg from "../assets/signLogin.jpeg";
import toast from "react-hot-toast";
import { BiSolidCameraPlus } from "react-icons/bi";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import { FcGoogle } from "react-icons/fc";

function Signup() {
  const [userImg, setUserImg] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [togglePassword, setTogglePasword] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      if (fileReader.readyState === fileReader.DONE) {
        setUserImg(fileReader.result);
        setError("");
      }
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userImg) {
      setError("userImg");
      return toast.error("Image is required.");
    }

    if (!name) {
      setError("name");
      return toast.error("Name is required.");
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setError("email");
      return toast.error("Please enter a valid email address.");
    }

    const phoneRegex = /^\d{10}$/;

    if (!phoneRegex.test(phone)) {
      setError("phone");
      return toast.error("Please enter a valid 10-digit phone number.");
    }

    if (password.length < 8) {
      setError("password");
      return toast.error("Password must be at least 8 characters long.");
    }

    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]).{8,}$/;

    if (!passwordRegex.test(password)) {
      setError("password");
      return toast.error(
        "Password must contain at least one special character, one uppercase letter, one lowercase letter, and one number."
      );
    }

    if (!privacyPolicy) {
      return toast.error(
        "Please agree to the Privacy Policy before signing up."
      );
    }

    try {
      const response = await axiosClient.post("jwtAuth/signup", {
        userImg,
        name,
        email,
        phone,
        password,
        privacyPolicy,
      });
      setUserImg("");
      setName("");
      setEmail("");
      setPhone("");
      setPassword("");
      toast.success(response.result);
      handleLogin();
    } catch (error) {
      return;
    }
  };

  async function handleLogin() {
    try {
      const response = await axiosClient.post("jwtAuth/login", {
        email,
        password,
      });
      setItem(KEY_ACCESS_TOKEN, response.result.accessToken);
      navigate("/");
    } catch (error) {
      return;
    }
  }

  const handleGoogleLogin = () => {
    toast.success(
      "By logging in with Google, you agree to the Terms and Conditions."
    );

    setTimeout(() => {
      window.open("https://api.bookandbuy.in/auth/google/callback", "_self");
    }, 5000);
  };

  const imgLabel =
    "flex justify-center items-center border rounded-full size-32 sm:size-52 text-blue-900 text-2xl border-blue-900 border-dashed";

  return (
    <div className="flex justify-center bg-white p-5 sm:py-5">
      <Helmet>
        <title>Signup - Book And Buy</title>
        <meta
          name="description"
          content="Create a Book And Buy account to start booking appointments and placing orders with local shops."
        />
      </Helmet>

      <div className="p-5 sm:bg-white rounded sm:rounded-lg border border-blue-900">
        <p className="text-lg sm:text-2xl font-medium text-center mb-3">
          Sign Up
        </p>
        <div className="flex items-center">
          <div className="sm:flex-1 hidden sm:block">
            <img
              src={loginImg}
              alt="User login illustration"
              className="size-96"
            />
          </div>
          <div className="sm:flex-1 flex flex-col gap-3 text-xs sm:text-sm">
            <form onSubmit={handleSubmit} className="flex flex-col gap-3">
              <div className="flex justify-center">
                {userImg ? (
                  <img
                    src={userImg}
                    alt="userImg"
                    className="border-2 border-green-500 border-dashed rounded-full size-32 sm:size-52"
                  />
                ) : (
                  <div
                    className={`${
                      error === "userImg" && "border-red-500 text-red-500"
                    } ${imgLabel}`}
                  >
                    <label htmlFor="inputImg">
                      <BiSolidCameraPlus className="cursor-pointer" />
                    </label>
                    <input
                      className={`hidden`}
                      id="inputImg"
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                  </div>
                )}
              </div>
              <input
                type="text"
                value={name}
                className={`${
                  name ? "border-green-500" : "border-blue-900"
                } ${
                  error === "name" && "border-red-500"
                } px-5 py-1 border rounded focus:outline-none`}
                placeholder="Name"
                onChange={(e) => {
                  setError("");
                  setName(e.target.value);
                }}
              />
              <input
                type="email"
                value={email}
                className={`${email ? "border-green-500" : "border-blue-900"} ${
                  error === "email" && "border-red-500"
                } px-5 py-1 border rounded focus:outline-none`}
                placeholder="Email"
                onChange={(e) => {
                  setError("");
                  setEmail(e.target.value);
                }}
              />
              <input
                type="tel"
                value={phone}
                className={`${phone ? "border-green-500" : "border-blue-900"} ${
                  error === "phone" && "border-red-500"
                } px-5 py-1 border rounded focus:outline-none`}
                placeholder="Phone"
                onChange={(e) => {
                  setError("");
                  setPhone(e.target.value);
                }}
              />
              <div
                className={`${
                  password ? "border-green-500" : "border-blue-900"
                } ${
                  error === "password" && "border-red-500"
                } flex items-center justify-between px-5 py-1 border rounded`}
              >
                <input
                  type={togglePassword ? `text` : `password`}
                  value={password}
                  className="focus:outline-none"
                  placeholder="Password"
                  onChange={(e) => {
                    setError("");
                    setPassword(e.target.value);
                  }}
                />
                <p
                  className="cursor-pointer"
                  onClick={() => setTogglePasword(!togglePassword)}
                >
                  {togglePassword ? <FaRegEye /> : <FaRegEyeSlash />}
                </p>
              </div>
              <label>
                <input
                  type="checkbox"
                  onChange={(e) => setPrivacyPolicy(!privacyPolicy)}
                />
                I have read and agree to the{" "}
                <Link to="/privacyPolicy" className="text-blue-900 underline">
                  Privacy Policy
                </Link>{" "}
                before signing up.
              </label>

              <input
                type="submit"
                className="px-5 py-1 text-white bg-blue-500 rounded focus:outline-none cursor-pointer"
              />
            </form>

            <p>
              Already have an account?{" "}
              <Link to="/login" className="text-blue-900 underline">
                Login
              </Link>
            </p>
            <button
              onClick={handleGoogleLogin}
              className="flex items-center justify-center gap-2 border border-black rounded px-5 py-1 font-medium"
            >
              <FcGoogle className="text-2xl" /> Google
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
