import React, { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { axiosClient } from "../utils/axiosClient";
import { useSelector } from "react-redux";
import { PiMaskSad } from "react-icons/pi";

function QueueStatus({ onClose }) {
  const appointmentRef = useRef();
  const [isClosing, setIsClosing] = useState(false);
  const [queue, setQueue] = useState([]);
  const shopId = useSelector((state) => state.shopsSlice.shopId);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        appointmentRef.current &&
        !appointmentRef.current.contains(event.target)
      ) {
        setIsClosing(true);
        setTimeout(onClose, 400);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleCloseClick = () => {
    setIsClosing(true);
    setTimeout(onClose, 400);
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    try {
      const response = await axiosClient.get(`appointment/user/${shopId}`);
      setQueue(response.result);
      console.log(response.result);
    } catch (e) {
      return;
    }
  };

  return (
    <div
      ref={appointmentRef}
      className={`fixed inset-4 sm:inset-10 md:inset-20 lg:inset-30 z-50 text-blue-900 border border-dashed border-blue-900 rounded-lg bg-white transition-all duration-300 ${
        isClosing ? "animate-fadeOut" : "animate-fadeIn"
      }`}
    >
      <div className="flex flex-col gap-6 rounded-lg bg-white">
        <div className="flex items-center justify-between font-bold text-lg border-b border-blue-900 px-4 rounded-t-lg bg-blue-900 text-white">
          <p className="bg-blue-900">Queue Status</p>
          <AiOutlineClose
            className="cursor-pointer bg-blue-900"
            onClick={handleCloseClick}
          />
        </div>

        {queue.length !== 0 ? (
          <div className="bg-white flex justify-center">
            <div className="w-full mx-4 sm:mx-16 md:mx-28 lg:mx-48">
              <div className="overflow-x-auto">
                <table className="border-collapse w-full">
                  <thead>
                    <tr className="flex">
                      <th className="p-1 bg-blue-900 text-white flex-1">
                        S.No.
                      </th>
                      <th className="p-1 bg-blue-900 text-white flex-1">
                        Name
                      </th>
                      <th className="p-1 bg-blue-900 text-white flex-1">
                        Date
                      </th>
                      <th className="p-1 bg-blue-900 text-white flex-1">
                        Time
                      </th>
                    </tr>
                  </thead>
                  <tbody className="block h-32 bg-white overflow-y-auto hideScrollbar">
                    {queue.map((data, idx) => {
                      const date = new Date(data.date);
                      const month = (date.getMonth() + 1)
                        .toString()
                        .padStart(2, "0");
                      const day = date.getDate().toString().padStart(2, "0");
                      const year = date.getFullYear();
                      const formattedDate = `${day}/${month}/${year}`;
                      return (
                        <tr
                          key={idx}
                          className="flex items-center text-center w-full border border-blue-900 bg-white"
                        >
                          <td className="p-1 flex-1">{idx + 1}</td>
                          <td className="p-1 flex-1">{data.name}</td>
                          <td className="p-1 flex-1">{formattedDate}</td>
                          <td className="p-1 flex-1">{data.fromTime}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-2 bg-white">
            <p className="bg-white text-center font-semibold">
              No Appointments today!
            </p>
            <p className="bg-white flex justify-center text-5xl">
              <PiMaskSad className="bg-white " />
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default QueueStatus;
