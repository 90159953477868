import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosClient } from "../utils/axiosClient";
import { BiSolidCameraPlus } from "react-icons/bi";
import { MdOutlineNavigateNext } from "react-icons/md";
import toast from "react-hot-toast";

function CreateShopFeatures() {
  const [feature, setFeature] = useState("");
  const [features, setFeatures] = useState([]);
  const [featureImg, setFeatureImg] = useState("");
  const [title, setTitle] = useState("");
  const params = useParams();
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      if (fileReader.readyState === fileReader.DONE) {
        setFeatureImg(fileReader.result);
      }
    };
  };

  const submitShopFeatures = async () => {
    try {
      const response = await axiosClient.post(`shop/features/${params.id}`, {
        feature,
        features,
      });
      setFeature("");
      setFeatures([]);
      toast.success(response.result);
    } catch (error) {
      toast.error(error);
      console.log(error);
    }
  };

  const addItems = () => {
    if (title && featureImg) {
      setFeatures([...features, { title, img: featureImg }]);
      setFeatureImg("");
      setTitle("");
    } else {
      toast.error("Please provide both a title and an image.");
    }
  };

  const imgLabel =
    "flex justify-center items-center border-2 rounded-xl w-48 h-48 text-blue-900 text-2xl bg-blue-100 border-blue-900 border-dashed";
  const input = "border-2 border-blue-900 rounded-lg px-2 text-blue-500 w-48";
  const submit =
    "bg-blue-900 rounded-full px-2 text-white cursor-pointer font-semibold w-48 sm:mt-0 hover:bg-blue-800 hover:text-gray-100";

  return (
    <div>
      <p className="text-center text-blue-900 font-bold text-xl">
        Enter Facilities
      </p>
      <div className="flex justify-center ">
        <div className="my-5">
          <input
            type="text"
            className={`${input}`}
            placeholder="Category"
            value={feature}
            onChange={(e) => setFeature(e.target.value)}
          />
          <div className="my-1">
            {featureImg ? (
              <div>
                <img
                  src={featureImg}
                  alt="userImg"
                  className="border-2 border-blue-900 border-dashed rounded-xl w-48 h-48"
                />
              </div>
            ) : (
              <div className={`${imgLabel}`}>
                <label htmlFor="inputImg">
                  <BiSolidCameraPlus className="bg-blue-100 cursor-pointer" />
                </label>
                <input
                  className={`${input} hidden`}
                  id="inputImg"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
            )}
          </div>

          <div>
            <input
              type="text"
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className={`${input} mb-1`}
            />
          </div>

          <button onClick={addItems} className={`${submit} my-1`}>
            Add Facility
          </button>
          <div
            onClick={() => {
              navigate(`/create-shop-products/${params.id}`);
            }}
          >
            <MdOutlineNavigateNext className={`${submit} text-2xl`} />
          </div>
        </div>
      </div>

      {features.length > 0 && (
        <div>
          <p className="text-center bg-blue-900 text-white font-semibold py-1 text-lg">
            {feature}
          </p>
          {/* CARD SECTION */}
          <div className="overflow-x-auto hideScrollbar">
            <div className="flex gap-4 w-max">
              {features.map((data, idx) => (
                <div
                  key={idx}
                  className="p-3 bg-white text-blue-900 rounded-xl font-bold w-48 my-5 text-center"
                >
                  <img
                    src={data.img}
                    alt="imgs"
                    className="rounded w-48 h-48"
                  />
                  <p style={{ backgroundColor: "white" }}>{data.title}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-center">
            <button
              onClick={submitShopFeatures}
              className={`${submit} w-48 mb-5`}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateShopFeatures;
