import React, { useEffect, useState } from "react";
import { axiosClient } from "../../utils/axiosClient";
import { useParams } from "react-router-dom";
import { PiMaskSad } from "react-icons/pi";
import Tooltip from "../Tooltip";
import { MdOutlineDelete } from "react-icons/md";
import { GiConfirmed, GiCancel } from "react-icons/gi";
import { TiCancel } from "react-icons/ti";
import toast from "react-hot-toast";
import ConfirmationModal from "../ConfirmationModal";

function AppointmentsList() {
  const [appointments, setAppointments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [appointmentId, setAppointmentId] = useState("");
  const [appointmentStatus, setAppointmentStatus] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const params = useParams();

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    try {
      const response = await axiosClient.get(`appointment/shop/${params.id}`);
      setAppointments(response.result);
    } catch (e) {
      return;
    }
  };

  const handleDelete = async () => {
    try {
      const response = await axiosClient.delete(
        `appointment/shop/${params.id}/${appointmentId}/${appointmentStatus}`
      );
      toast.success(response.result);
      fetchAppointments();
    } catch (e) {
      return;
    }
    handleCloseModal();
  };

  const toggleAppointmentStatus = async () => {
    try {
      const response = await axiosClient.put(
        `appointment/shop/${params.id}/${appointmentId}`,
        {
          appointmentStatus: appointmentStatus,
        }
      );
      toast.success(response.result);
      setAppointments((prev) =>
        prev.map((appointment) =>
          appointment._id === appointmentId
            ? { ...appointment, status: appointmentStatus }
            : appointment
        )
      );
    } catch (error) {
      return;
    }
    handleCloseModal();
  };

  const handleOpenModal = (appointmentId, status, confirmation) => {
    setAppointmentId(appointmentId);
    setAppointmentStatus(status);
    if (confirmation) {
      return setConfirmationModal(true);
    }
    return setShowModal(true);
  };

  const handleCloseModal = () => {
    setConfirmationModal(false);
    setShowModal(false);
    setAppointmentId("");
    setAppointmentStatus("");
  };

  return (
    <div className="flex justify-center">
      <div>
        <p className="text-center py-1 text-lg sm:text-2xl text-blue-900">
          All Appointments
        </p>
        {appointments.length === 0 ? (
          <div className="flex flex-col gap-5 mt-2 mb-5 h-screen sm:h-96 text-blue-900">
            <p className="flex justify-center text-8xl">
              <PiMaskSad />
            </p>
            <p className="text-center font-semibold">No Appointment Yet!</p>
          </div>
        ) : (
          <ul className="flex flex-col gap-2 mt-2 mb-5">
            <div className="flex justify-center">
              <div className="w-screen px-1 sm:px-16 md:px-28 lg:px-48">
                <div className="overflow-x-auto">
                  <table className="border-collapse w-full">
                    <thead className="text-xs sm:text-base">
                      <tr className="flex">
                        <th className="p-1 bg-blue-900 text-white flex-1">
                          Customer
                        </th>
                        <th className="p-1 bg-blue-900 text-white flex-1">
                          Contact
                        </th>
                        <th className="p-1 bg-blue-900 text-white flex-1">
                          Date
                        </th>
                        <th className="p-1 bg-blue-900 text-white flex-1">
                          Time
                        </th>
                        <th className="p-1 bg-blue-900 text-white flex-1">
                          Status
                        </th>
                        <th className="p-1 bg-blue-900 text-white flex-1">
                          Manage
                        </th>
                      </tr>
                    </thead>
                    <tbody className="block h-96 overflow-y-auto hideScrollbar">
                      {appointments.map((data, idx) => {
                        const date = new Date(data.date);
                        const month = (date.getMonth() + 1)
                          .toString()
                          .padStart(2, "0");
                        const day = date.getDate().toString().padStart(2, "0");
                        const year = date.getFullYear();
                        const formattedDate = `${day}/${month}/${year}`;

                        return (
                          <tr
                            key={data._id}
                            className="flex items-center text-xs sm:text-sm text-center w-full border border-gray-200 bg-white"
                          >
                            <td className="p-1 flex-1">
                              <div className="flex flex-col items-center">
                                <Tooltip tooltipText={data.email}>
                                  <img
                                    src={data.imgUrl}
                                    alt={data.name}
                                    className="size-6 sm:size-8 rounded-full"
                                  />
                                </Tooltip>
                                <p>{data.name}</p>
                              </div>
                            </td>
                            <td className="p-1 flex-1">{data.phone}</td>
                            <td className="p-1 flex-1">{formattedDate}</td>
                            <td className="p-1 flex-1">{data.fromTime}</td>
                            <td
                              className={`p-1 flex-1 flex ${
                                data.status === ""
                                  ? "justify-around"
                                  : "justify-center"
                              }`}
                            >
                              <Tooltip
                                tooltipText={
                                  data.status === "" ? "Confirm" : "Confirmed"
                                }
                              >
                                <GiConfirmed
                                  className={`${
                                    data.status === "canceled"
                                      ? "hidden"
                                      : "block"
                                  } text-green-500`}
                                  onClick={
                                    data.status === "confirmed"
                                      ? null
                                      : () =>
                                          handleOpenModal(
                                            data._id,
                                            "confirmed",
                                            "confirmation"
                                          )
                                  }
                                />
                              </Tooltip>
                              <Tooltip
                                tooltipText={
                                  data.status === "" ? "Cancel" : "Canceled"
                                }
                              >
                                <GiCancel
                                  className={`${
                                    data.status === "confirmed"
                                      ? "hidden"
                                      : "block"
                                  } text-red-500`}
                                  onClick={
                                    data.status === "canceled"
                                      ? null
                                      : () =>
                                          handleOpenModal(
                                            data._id,
                                            "canceled",
                                            "confirmation"
                                          )
                                  }
                                />
                              </Tooltip>
                            </td>
                            {data.status === "confirmed" && (
                              <td className="p-1 flex-1 flex justify-center">
                                <Tooltip tooltipText="Mark As Completed">
                                  <GiConfirmed
                                    className="text-green-500 "
                                    onClick={() =>
                                      handleOpenModal(data._id, "completed")
                                    }
                                  />
                                </Tooltip>
                              </td>
                            )}
                            {data.status === "canceled" && (
                              <td className="p-1 flex-1 flex justify-center">
                                <Tooltip tooltipText="Delete">
                                  <MdOutlineDelete
                                    className="text-red-500"
                                    onClick={() => handleOpenModal(data._id)}
                                  />
                                </Tooltip>
                              </td>
                            )}
                            {data.status === "" && (
                              <td className="p-1 flex-1 flex justify-center cursor-not-allowed">
                                <TiCancel className="text-red-500 text-base" />
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ul>
        )}
      </div>

      <ConfirmationModal
        show={showModal}
        onClose={handleCloseModal}
        onConfirm={handleDelete}
        message={
          appointmentStatus === "completed"
            ? "You've completed the appointment?"
            : "You want to delete the appointment?"
        }
        appointmentAction="No, Keep It"
        action="Yes, Remove It"
      />

      <ConfirmationModal
        show={confirmationModal}
        onClose={handleCloseModal}
        onConfirm={toggleAppointmentStatus}
        message={
          appointmentStatus === "confirmed"
            ? "You want to confirm the appointment?"
            : "You want to cancel the appointment?"
        }
        appointmentAction="No"
        action="Yes"
      />
    </div>
  );
}

export default AppointmentsList;
