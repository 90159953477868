import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";
import BookAndBuy_Logo from "../assets/BookAndBuyLogo.jpg";

function Sidebar({ onClose }) {
  const [isClosing, setIsClosing] = useState(false);

  const handleCloseClick = () => {
    setIsClosing(true);
    setTimeout(onClose, 400);
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-start">
      <div
        className={`fixed inset-0 bg-black transition-opacity duration-300 ${
          isClosing ? "opacity-0" : "opacity-50"
        }`}
        onClick={handleCloseClick}
      ></div>
      <div
        className={`relative h-screen w-80 bg-white text-sm md:text-base lg:text-lg transition-transform duration-300 ${
          isClosing ? "animate-sidebarSlideOut" : "animate-sidebarSlideIn"
        }`}
      >
        <div className="flex justify-between items-center pr-1">
          <Link to="/">
            <img
              src={BookAndBuy_Logo}
              alt="Logo"
              className="size-11 p-1 rounded-full"
            />
          </Link>
          <AiOutlineClose
            className="text-lg sm:text-2xl font-semibold cursor-pointer"
            onClick={handleCloseClick}
          />
        </div>
        <div className="flex flex-col mt-2 text-black">
          <NavLink
            className={({ isActive }) =>
              `hover:text-blue-500 border-y border-black py-1 px-4 ${
                isActive ? "text-yellow-500" : ""
              }`
            }
            to="/aboutUs"
          >
            About Us
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              `py-1 px-4 ${isActive ? "text-yellow-500" : ""}`
            }
            to="/allOrders"
          >
            Orders
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              `hover:text-blue-500 border-y border-black py-1 px-4 ${
                isActive ? "text-yellow-500" : ""
              }`
            }
            to="/allAppointments"
          >
            Appointments
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
