import React, { useEffect, useState } from "react";
import { axiosClient } from "../../utils/axiosClient";
import { PiMaskSad } from "react-icons/pi";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

function AllOrders() {
  const [orders, setOrders] = useState([]);
  const params = useParams();

  const handleNextStatus = async (customerId, currentStatus) => {
    let nextStatus;
    if (currentStatus === "Pending") nextStatus = "Packed";
    else if (currentStatus === "Packed") nextStatus = "Delivered";

    try {
      const response = await axiosClient.put(
        `orders/shop/updateStatus/${params.id}`,
        {
          customerId,
          orderStatus: nextStatus,
        }
      );
      toast.success(response.result);
      fetchOrders();
    } catch (e) {
      return;
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await axiosClient.get(`orders/shop/${params.id}`);
      setOrders(response.result);
    } catch (e) {
      return;
    }
  };

  const handleDeleteOrders = async (customerId) => {
    try {
      const response = await axiosClient.delete(
        `orders/deleteOrders/${params.id}/${customerId}`
      );
      toast.success(response.result);
      fetchOrders();
    } catch (e) {
      return;
    }
  };

  return (
    <div className="flex justify-center">
      <div>
        <p className="text-center text-lg py-1 sm:text-2xl text-blue-900">
          Total Orders
        </p>
        {orders.length === 0 ? (
          <div className="flex flex-col gap-5 mt-2 mb-5 h-screen sm:h-96 text-blue-900">
            <p className="flex justify-center text-8xl">
              <PiMaskSad />
            </p>
            <p className="text-center font-semibold">No Orders Yet!</p>
          </div>
        ) : (
          <div className="flex items-center justify-center mx-1">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 max-w-screen-lg py-16">
              {orders.map((data, idx) => (
                <div
                  key={idx}
                  className="border-2 border-blue-900 rounded shadow-lg overflow-hidden"
                >
                  <div className="flex justify-around items-center border-b-2 border-blue-900 py-2 ">
                    <img
                      src={data?.imgUrl?.url}
                      alt={data.email}
                      className="rounded-full border border-blue-900 size-10 md:size-14"
                    />
                    <div className="flex flex-col gap-1 text-xs">
                      <p className="font-semibold ">{data.name}</p>
                      <p className="font-semibold ">{data.email}</p>
                      <p className="font-semibold ">{data.phone}</p>
                    </div>
                  </div>
                  <ul className="flex flex-col gap-2 px-1 my-1 h-60 overflow-scroll hideScrollbar">
                    {data.orders.map((item, index) => (
                      <li
                        className="flex justify-around items-center w-60 sm:w-72 md:w-80 lg:w-96 border border-blue-900 py-1 rounded transition-transform transform hover:scale-95"
                        key={index}
                      >
                        <img
                          src={item.productId.imgUrl.url}
                          alt={item.productId.name}
                          className="rounded border border-blue-900 size-16"
                        />
                        <div className="w-28 sm:w-40">
                          <p className="font-medium">{item.productId.name}</p>
                          <div className="flex justify-between items-center">
                            <p>
                              Qty:
                              <span className="font-medium">
                                {item.productQuantity}
                              </span>{" "}
                            </p>
                            <p>
                              ₹:
                              <span className="font-medium">
                                {item.productId.price}
                              </span>{" "}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="flex justify-around items-center py-1 border-t-2 border-blue-900">
                    <p className="font-semibold">
                      <span className="font-medium">Total: </span> ₹{" "}
                      {data.orders.reduce(
                        (acc, item) => acc + item.totalAmount,
                        0
                      )}
                    </p>
                    <div className="flex flex-col items-center gap-1">
                      <p
                        className={`font-medium text-xs sm:text-sm
                        ${data.status === "Pending" && "text-yellow-500"} 
                        ${data.status === "Packed" && "text-blue-500 "} 
                        ${data.status === "Delivered" && "text-green-500"} `}
                      >
                        {data.status}
                      </p>
                      {data.status !== "Delivered" && (
                        <button
                          onClick={() =>
                            handleNextStatus(data.customer, data.status)
                          }
                          className="text-blue-900 border border-blue-900 px-1 text-xs sm:text-sm rounded"
                          disabled={data.status === "Delivered"}
                        >
                          {data.status === "Pending"
                            ? "Mark as Packed"
                            : data.status === "Packed"
                            ? "Mark as Delivered"
                            : "Delivered"}
                        </button>
                      )}
                      {data.status === "Delivered" && (
                        <button
                          onClick={() => handleDeleteOrders(data.customer)}
                          className="text-blue-900 border border-blue-900 px-1 text-xs sm:text-sm rounded"
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AllOrders;
