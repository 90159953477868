import React from "react";
import DhruvCEO from "../assets/DhruvCEO.jpg";
import vashuCoFounder from "../assets/VasuCo-founder.jpg";
import howItStarted from "../assets/how_it_started.jpg";
import ourSolution from "../assets/OUR_SOLUTION.jpg";
import ourMission from "../assets/OUR_MISSION.jpg";
import { Helmet } from "react-helmet";

function AboutUs() {
  return (
    <div className="bg-white flex items-center flex-col gap-y-6 sm:gap-y-12 sm:px-28 py-8">
      {/* SEO Meta Tags */}
      <Helmet>
        <title>About Us - Book And Buy</title>
        <meta
          name="description"
          content="Learn more about Book And Buy, your trusted platform for booking appointments and placing orders with local shops."
        />
      </Helmet>

      {/* CEO */}
      <div className="flex flex-col-reverse sm:flex-row sm:items-center sm:gap-10">
        <div className="sm:flex-1 text-center sm:text-left">
          <p className="hidden sm:block text-2xl font-semibold">Dhruv Garg</p>
          <p className="hidden sm:block font-medium -mt-1 mb-2">CEO</p>
          <p className="sm:w-auto text-xs sm:text-sm mx-5 sm:mx-0">
            Dhruv Garg is a visionary leader with a passion for innovation and
            excellence. With a deep understanding of the industry and a
            relentless drive for success, he has steered Book And Buy towards
            achieving its mission of solving real-world problems. Under his
            leadership, the company continues to grow and make a positive
            impact.
          </p>
        </div>
        <div className="sm:flex-1 flex flex-col items-center sm:justify-center">
          <img
            src={DhruvCEO}
            alt="Dhruv Garg - CEO"
            className="w-28 h-28 sm:w-56 sm:h-56 rounded-full mb-1 sm:mb-0"
          />
          <p className="sm:hidden block text-sm font-semibold">Dhruv Garg</p>
          <p className="sm:hidden block text-xs font-medium -mt-1 mb-2">CEO</p>
        </div>
      </div>

      {/* CO-FOUNDER */}
      <div className="flex flex-col sm:flex-row items-center sm:gap-10">
        <div className="sm:flex-1 flex flex-col items-center sm:justify-center">
          <img
            src={vashuCoFounder}
            alt="Vashu Sharma - Co-Founder"
            className="w-28 h-28 sm:w-52 sm:h-52 rounded-full mb-1 sm:mb-0"
          />
          <p className="sm:hidden block text-sm font-semibold">Vashu Sharma</p>
          <p className="sm:hidden block text-xs font-medium -mt-1 mb-2">
            Co-Founder
          </p>
        </div>
        <div className="sm:flex-1 text-center sm:text-left">
          <p className="hidden sm:block text-2xl font-semibold">Vashu Sharma</p>
          <p className="hidden sm:block font-medium -mt-1 mb-2">Co-Founder</p>
          <p className="sm:w-auto text-xs sm:text-sm mx-5 sm:mx-0">
            As Co-Founder of Book And Buy, Vashu Sharma brings a wealth of
            experience and a forward-thinking approach to the company. His
            collaborative spirit and commitment to solving complex challenges
            have been instrumental in shaping the company’s vision and strategy.
          </p>
        </div>
      </div>

      {/* HOW IT STARTED */}
      <div className="flex flex-col-reverse sm:flex-row items-center sm:gap-10">
        <div className="flex-1 space-y-4 text-center sm:text-left">
          <p className="hidden sm:block text-2xl font-semibold">
            <span className="font-normal text-xl">How It</span> Started
          </p>
          <p className="sm:w-auto text-xs sm:text-sm mx-5 sm:mx-0">
            Book And Buy was born in 2024 out of a shared vision between two
            colleagues working in a cooperative company. Frustrated by the
            <span className="font-semibold">
              {" "}
              inefficiencies of everyday life,
            </span>
            particularly the time wasted
            <span className="font-semibold"> in long queues,</span> they set out
            to create a solution that would revolutionize this experience.
            Driven by a desire to
            <span className="font-semibold"> make a global impact,</span> they
            embarked on a journey to eliminate unnecessary waiting times and
            bring convenience to people’s lives.
          </p>
        </div>
        <div className="flex-1 flex flex-col items-center sm:justify-center">
          <p className="text-lg sm:hidden font-semibold">
            <span className="font-normal text-sm">How It</span> Started
          </p>
          <img
            src={howItStarted}
            alt="How Book And Buy Started"
            className="w-32 h-32 sm:w-96 sm:h-96"
          />
        </div>
      </div>

      {/* OUR SOLUTION */}
      <div className="flex flex-col sm:flex-row items-center sm:gap-10">
        <div className="flex-1 flex flex-col items-center sm:justify-center">
          <p className="text-lg sm:hidden font-semibold">
            <span className="font-normal text-sm">Our</span> Solution
          </p>
          <img
            src={ourSolution}
            alt="Our Solution"
            className="w-32 h-32 sm:w-96 sm:h-96"
          />
        </div>
        <div className="flex-1 space-y-4 text-center sm:text-left">
          <p className="hidden sm:block text-2xl font-semibold">
            <span className="font-normal text-xl">Our</span> Solution
          </p>
          <p className="sm:w-auto text-xs sm:text-sm mx-5 sm:mx-0">
            The idea was shared with a{" "}
            <span className="font-semibold">college friend</span> involved in
            web development, who was immediately impressed and began working on
            what would become Book And Buy.{" "}
            <span className="font-semibold">After 20 weeks</span> of dedicated
            effort, the team{" "}
            <span className="font-semibold">created a web application</span>{" "}
            that helps people{" "}
            <span className="font-semibold">book appointments</span> and{" "}
            <span className="font-semibold">find nearby shops</span> offering
            the services they need, all in one convenient platform.
          </p>
        </div>
      </div>

      {/* OUR MISSION */}
      <div className="flex flex-col-reverse sm:flex-row items-center sm:gap-10">
        <div className="flex-1 space-y-4 text-center sm:text-left">
          <p className="hidden sm:block text-2xl font-semibold">
            <span className="font-normal text-xl">Our</span> Mission
          </p>
          <p className="sm:w-auto text-xs sm:text-sm mx-5 sm:mx-0">
            <span className="font-semibold">Book And Buy aims</span> to make
            life easier and{" "}
            <span className="font-semibold">save valuable time</span> by
            eliminating the need to wait in long lines or stand in front of
            shops. Our platform allows users to{" "}
            <span className="font-semibold">schedule services</span> like{" "}
            <span className="font-semibold">
              haircuts, doctor appointments, ordering groceries, vegetables,
              fruits
            </span>{" "}
            from nearby shops, and more. Users can then conveniently pick up
            their orders from the shop themselves, ensuring they receive their
            services on time without wasting a single second.
          </p>
        </div>
        <div className="flex-1 flex flex-col items-center sm:justify-center">
          <p className="text-lg sm:hidden font-semibold">
            <span className="font-normal text-sm">Our</span> Mission
          </p>
          <img
            src={ourMission}
            alt="Our Mission"
            className="w-32 h-32 sm:w-96 sm:h-96"
          />
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
