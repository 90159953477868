import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import {
  addToCart,
  deleteFromCart,
  removeFromCart,
} from "../redux/slices/cartSlice";

function CartItem({ item }) {
  const dispatch = useDispatch();

  const productId = item.productId._id;
  const quantity = item.quantity;
  const shopId = item.shopId;

  return (
    <div className="flex items-center gap-2 border-b-2 border-black px-1">
      <div className="size-20">
        <img
          src={item.productId.imgUrl.url}
          alt={item.productId.name}
          className="w-full h-full object-contain rounded"
        />
      </div>
      <div className="flex flex-grow justify-between">
        <div className="flex flex-col gap-1">
          <p className="font-medium">{item.productId.name}</p>
          <p>
            ₹ <span className="font-medium">{item.productId.price}</span>
          </p>
          <div className="border-2 border-black w-fit">
            <span
              className="py-1 px-3 border-r-2 border-black inline-block text-xl cursor-pointer"
              onClick={() => dispatch(removeFromCart(productId))}
            >
              -
            </span>
            <span className="text-center w-10 inline-block font-semibold">
              {item.quantity}
            </span>
            <span
              className="py-1 px-3 border-l-2 border-black inline-block text-xl cursor-pointer"
              onClick={() =>
                dispatch(addToCart({ productId, quantity, shopId }))
              }
            >
              +
            </span>
          </div>
          <p className="my-1">
            SubTotal:₹{" "}
            <span className="font-semibold">
              {item.productId.price * quantity}
            </span>
          </p>
        </div>
        <div className="cursor-pointer mt-1 h-1">
          <AiOutlineClose onClick={() => dispatch(deleteFromCart(productId))} />
        </div>
      </div>
    </div>
  );
}

export default CartItem;
