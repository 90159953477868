import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllAppointments } from "../redux/slices/shopsSlice";
import toast from "react-hot-toast";
import Tooltip from "../components/Tooltip";
import ConfirmationModal from "../components/ConfirmationModal";
import { axiosClient } from "../utils/axiosClient";

import { PiMaskSad } from "react-icons/pi";
import { TbCalendarCancel } from "react-icons/tb";
import { GiCancel, GiConfirmed } from "react-icons/gi";
import { MdOutlinePending } from "react-icons/md";

function AllAppointments() {
  const [showModal, setShowModal] = useState(false);
  const [cancelAppointmentId, setCancelAppointmentId] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allAppointments = useSelector(
    (state) => state.shopsSlice.appointmentsData
  );

  useEffect(() => {
    try {
      dispatch(getAllAppointments());
    } catch (error) {
      return;
    }
  }, [dispatch]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const cancelAppointment = async () => {
    try {
      const response = await axiosClient.delete(
        `appointment/${cancelAppointmentId}`
      );
      toast.success(response.result);
      dispatch(getAllAppointments());
      handleCloseModal();
      setCancelAppointmentId("");
    } catch (error) {
      return;
    }
  };

  return (
    <div className="flex justify-center">
      <div className="text-blue-900">
        <p className="text-center py-1 text-lg sm:text-2xl">
          Your Appointments
        </p>
        {allAppointments?.length === 0 ? (
          <div className="flex flex-col gap-5 mt-2 mb-5 h-screen sm:h-96">
            <p className="flex justify-center text-8xl">
              <PiMaskSad />
            </p>
            <p className="text-center font-semibold">No Appointments Yet!</p>
          </div>
        ) : (
          <ul className="flex flex-col gap-2 mt-2 mb-5">
            <div className="flex justify-center">
              <div className="w-screen px-4 sm:px-16 md:px-28 lg:px-48">
                <div className="overflow-x-auto">
                  <table className="border-collapse w-full">
                    <thead className="text-sm sm:text-base">
                      <tr className="flex">
                        <th className="p-1 bg-blue-900 text-white flex-1">
                          Shop Name
                        </th>
                        <th className="p-1 bg-blue-900 text-white flex-1">
                          Date
                        </th>
                        <th className="p-1 bg-blue-900 text-white flex-1">
                          Time
                        </th>
                        <th className="p-1 bg-blue-900 text-white flex-1">
                          Status
                        </th>
                        <th className="p-1 bg-blue-900 text-white flex-1">
                          Cancel
                        </th>
                      </tr>
                    </thead>
                    <tbody className="block h-96 overflow-y-auto hideScrollbar">
                      {allAppointments?.map((data, idx) => {
                        const date = new Date(data.date);
                        const month = (date.getMonth() + 1)
                          .toString()
                          .padStart(2, "0");
                        const day = date.getDate().toString().padStart(2, "0");
                        const year = date.getFullYear();
                        const formattedDate = `${day}/${month}/${year}`;

                        return (
                          <tr
                            key={idx}
                            className="flex items-center text-xs sm:text-sm text-center w-full border border-gray-200 bg-white"
                          >
                            <td
                              className="p-1 flex-1 cursor-pointer"
                              onClick={() =>
                                navigate(`/shop-detail/${data.shopId}`)
                              }
                            >
                              {data.shopName}
                            </td>
                            <td className="p-1 flex-1">{formattedDate}</td>
                            <td className="p-1 flex-1">{data.fromTime}</td>
                            <td className="p-1 flex-1 flex justify-center">
                              {data.status === "confirmed" && (
                                <Tooltip tooltipText="Confirmed">
                                  <GiConfirmed className="text-green-500" />
                                </Tooltip>
                              )}
                              {data.status === "canceled" && (
                                <Tooltip tooltipText="Canceled">
                                  <GiCancel className="text-red-500" />
                                </Tooltip>
                              )}
                              {data.status === "" && (
                                <Tooltip tooltipText="Pending">
                                  <MdOutlinePending className="text-yellow-500 text-base" />
                                </Tooltip>
                              )}
                            </td>
                            <td
                              className="p-1 flex-1"
                              onClick={() => {
                                setCancelAppointmentId(data._id);
                                setShowModal(true);
                              }}
                            >
                              <TbCalendarCancel className="mx-auto text-lg sm:text-xl cursor-pointer" />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ul>
        )}
      </div>

      <ConfirmationModal
        show={showModal}
        onClose={handleCloseModal}
        onConfirm={cancelAppointment}
        message="You want to cancel the appointment?"
        appointmentAction="No, Keep It"
        action="Yes, Cancel It"
      />
    </div>
  );
}

export default AllAppointments;
