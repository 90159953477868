import React from "react";

function CancellationAndRefund() {
  const cancellationAndRefund = [
    "Cancellations will be considered only if the request is made within 7 days of placing the order. However, the cancellation request may not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of shipping them.",
    "DHRUV GARG does not accept cancellation requests for perishable items like flowers, eatables etc. However, refund/replacement can be made if the customer establishes that the quality of product delivered is not good.",
    "In case of receipt of damaged or defective items please report the same to our Customer Service team. The request will, however, be entertained once the merchant has checked and determined the same at his own end. This should be reported within 7 days of receipt of the products.",
    "In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within 7 days of receiving the product. The Customer Service Team after looking into your complaint will take an appropriate decision.",
    "In case of complaints regarding products that come with a warranty from manufacturers, please refer the issue to them.",
    "In case of any Refunds approved by the DHRUV GARG, it’ll take 3-5 days for the refund to be processed to the end customer.",
  ];

  return (
    <div className="flex flex-col items-center mt-0 sm:-mt-5 md:mt-0 bg-white space-y-2 py-5">
      <p className="text-lg sm:text-xl font-semibold">Cancellation & Refund</p>
      <p className="text-sm sm:text-base font-medium px-5 sm:px-0">
        DHRUV GARG believes in helping its customers as far as possible, and has
        therefore a liberal cancellation policy. Under this policy:
      </p>
      <ul className="space-y-5">
        {cancellationAndRefund.map((terms, idx) => (
          <li
            key={idx}
            className="list-disc mx-10 sm:mx-20 md:mx-30 lg:mx-40  text-xs sm:text-sm"
          >
            {terms}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CancellationAndRefund;
