import React, { useState } from "react";
import { GrEdit } from "react-icons/gr";
import { MdOutlineDelete } from "react-icons/md";
import { axiosClient } from "../../utils/axiosClient";
import toast from "react-hot-toast";
import Tooltip from "../Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { getShopAdmin } from "../../redux/slices/shopsSlice";

function Products({ product, productsId }) {
  const [quantity, setQuantity] = useState(1);
  const [name, setName] = useState(product.name);
  const [price, setPrice] = useState(product.price);
  const [detail, setDetail] = useState(product.detail);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const dispatch = useDispatch();
  const shopId = useSelector((state) => state.shopsSlice.shopId);

  const deleteProduct = async (productId, imgId) => {
    try {
      const response = await axiosClient.delete(
        `deleteShop/products/${shopId}/${productsId}/${productId}?imgId=${imgId}`
      );
      toast.success(response.result);
      dispatch(getShopAdmin(shopId));
    } catch (error) {
      return;
    }
  };

  const updateProduct = async () => {
    try {
      const response = await axiosClient.put(
        `updateShop/product/${shopId}/${product._id}`,
        {
          name,
          price,
          detail,
        }
      );
      toast.success(response.result);
      setToggleUpdate(false);
      dispatch(getShopAdmin(shopId));
    } catch (error) {
      setToggleUpdate(false);
    }
  };

  return (
    <div className="hover:shadow-lg transition-transform transform hover:scale-105">
      <div className="p-2 sm:p-3 bg-white rounded-lg text-xs sm:text-sm">
        <div className="flex justify-between items-center mb-2 text-base sm:text-lg text-blue-900">
          <Tooltip tooltipText="Edit">
            <GrEdit onClick={() => setToggleUpdate(true)} />
          </Tooltip>
          <Tooltip tooltipText="Delete">
            <MdOutlineDelete
              onClick={() =>
                deleteProduct(product._id, product.imgUrl.publicId)
              }
            />
          </Tooltip>
        </div>
        <img
          src={product.imgUrl.url}
          alt="img"
          className="size-40 sm:size-52 rounded-md"
        />
        {toggleUpdate ? (
          <>
            <input
              type="text"
              placeholder="Product Name"
              className="text-center font-medium w-full my-1 border border-blue-900 rounded"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div className="flex justify-between">
              <p>
                ₹{" "}
                <input
                  type="text"
                  placeholder="Price"
                  className="border border-blue-900 rounded font-medium w-7 text-center sm:font-semibold"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </p>
              <div className="flex items-center gap-1">
                <p>Qty</p>
                <select
                  name="quantity"
                  id="quantity"
                  value={quantity}
                  className="font-medium sm:font-semibold cursor-pointer focus:outline-none"
                  onChange={(e) => setQuantity(parseInt(e.target.value))}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
            </div>
            <input
              type="text"
              placeholder="Detail"
              className="border border-blue-900 rounded w-full my-1"
              value={detail}
              onChange={(e) => setDetail(e.target.value)}
            />
            <button
              onClick={updateProduct}
              className="border border-blue-900 w-full rounded font-medium"
            >
              Submit
            </button>
          </>
        ) : (
          <>
            <p className="text-center font-medium my-1">{product.name}</p>
            <div className="flex justify-between">
              <p>
                ₹{" "}
                <span className="font-medium sm:font-semibold">
                  {product.price}
                </span>
              </p>
            </div>
            <p className="my-1 h-5 w-40 sm:w-52 text-xs text-gray-800">{product.detail}</p>
          </>
        )}
      </div>
    </div>
  );
}

export default Products;
