import React from "react";

function Facilities({ facility }) {
  return (
    <div className="font-medium p-2 sm:p-3 bg-white rounded-lg text-center hover:shadow-lg overflow-hidden transition-transform transform hover:scale-105">
      <img src={facility.imgUrl.url} alt="img" className="size-40 sm:size-52 rounded-md" />
      <p className="text-xs sm:text-sm mt-2 sm:mt-3">{facility.title}</p>
    </div>
  );
}

export default Facilities;
