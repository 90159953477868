import React, { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { axiosClient } from "../../utils/axiosClient";
import toast from "react-hot-toast";
import { BiSolidCameraPlus } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getShopAdmin } from "../../redux/slices/shopsSlice";

const imgLabel =
  "flex justify-center items-center border-2 rounded-xl w-48 h-48 text-blue-900 text-2xl bg-blue-100 border-blue-900 border-dashed";

const input = "border-2 border-blue-900 rounded-lg px-2 text-blue-500 w-48";

function AddShopProducts({ id, onClose }) {
  const updateRef = useRef();
  const [isClosing, setIsClosing] = useState(false);
  const [productImg, setProductImg] = useState("");
  const [productName, setProductName] = useState("");
  const [productDetail, setProductDetail] = useState("");
  const [price, setPrice] = useState(0);
  const dispatch = useDispatch();
  const shopId = useSelector((state) => state.shopsSlice.shopId);

  useEffect(() => {
    function handleClickOutside(event) {
      if (updateRef.current && !updateRef.current.contains(event.target)) {
        setIsClosing(true);
        setTimeout(onClose, 400);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleCloseClick = () => {
    setIsClosing(true);
    setTimeout(onClose, 400);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      if (fileReader.readyState === fileReader.DONE) {
        setProductImg(fileReader.result);
      }
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosClient.post(`updateShop/product/${shopId}/${id}`, {
        img: productImg,
        name: productName,
        detail: productDetail,
        price,
      });

      toast.success(response.result);
      dispatch(getShopAdmin(shopId));
      onClose();
    } catch (error) {
      return
    }
  };

  return (
    <div
      ref={updateRef}
      className={`fixed inset-4 sm:inset-10 md:inset-20 lg:inset-30 z-50 text-blue-900 border border-dashed border-blue-900 rounded-lg bg-white transition-all duration-300 ${
        isClosing ? "animate-fadeOut" : "animate-fadeIn"
      }`}
    >
      <div className="flex justify-between items-center p-4 border-b">
        <h2 className="text-xl font-semibold">Add Product</h2>
        <button onClick={handleCloseClick}>
          <AiOutlineClose size={24} />
        </button>
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center gap-6 p-4 max-h-80"
      >
        <div className="my-1">
          {productImg ? (
            <div>
              <img
                src={productImg}
                alt="userImg"
                className="border-2 border-blue-900 border-dashed rounded-xl w-48 h-48"
              />
            </div>
          ) : (
            <div className={`${imgLabel}`}>
              <label htmlFor="inputImg">
                <BiSolidCameraPlus className="bg-blue-100 cursor-pointer" />
              </label>
              <input
                className={`${input} hidden`}
                id="inputImg"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
            </div>
          )}
        </div>
        <div>
          <input
            type="text"
            placeholder="Product Name"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            className={`${input} mb-1`}
          />
        </div>
        <div>
          <textarea
            placeholder="Enter product detail (Limit: 50 words)"
            value={productDetail}
            onChange={(e) => setProductDetail(e.target.value)}
            className={`${input} mb-1 h-48`}
            maxLength="50"
          />
        </div>
        <div className={`${input} mb-1 flex gap-2`}>
          <p className="text-blue-900">₹: </p>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="w-36 outline-none"
          />
        </div>

        <button type="submit" className="p-2 bg-blue-500 text-white rounded">
          Update
        </button>
      </form>
    </div>
  );
}

export default AddShopProducts;
