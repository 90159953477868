import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import CartItem from "./CartItem";
import { BsCartX } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { axiosClient } from "../utils/axiosClient";
import { resetCart } from "../redux/slices/cartSlice";
import ConfirmationModal from "./ConfirmationModal";
import { useNavigate } from "react-router-dom";

function Cart({ onClose }) {
  const [isClosing, setIsClosing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cart = useSelector((state) => state?.cartSlice?.cart);
  const myProfile = useSelector((state) => state?.appConfigReducer?.myProfile);

  let totalAmount = 0;
  cart.forEach((item) => (totalAmount += item.quantity * item.productId.price));
  const isCartEmpty = cart.length === 0;

  const handleCloseClick = () => {
    setIsClosing(true);
    setTimeout(onClose, 400); // Delay onClose to allow animation to finish
  };

  const handleCloseModal = () => {
    setShowModal(false);
    return;
  };
  const handleConfirm = () => {
    navigate("/updateProfile");
    handleCloseModal();
  };

  const handleOrder = async () => {
    try {
      if (myProfile?.phone) {
        const response = await axiosClient.post("orders/", {
          products: cart,
        });

        toast.success(response.result);
        dispatch(resetCart());
      } else {
        setShowModal(true);
        return;
      }
    } catch (e) {
      toast.error(e);
    }
  };

  return (
    <div className={`fixed inset-0  flex justify-end z-50 `}>
      <div
        className={`fixed inset-0 bg-black transition-opacity duration-300 ${
          isClosing ? "opacity-0" : "opacity-50"
        }`}
        onClick={handleCloseClick}
      ></div>
      <div
        className={`relative h-screen w-60 sm:w-80 bg-white text-base lg:text-lg transition-transform duration-300 ${
          isClosing ? "animate-slideOut" : "animate-slideIn"
        }`}
      >
        <div className="h-14 flex items-center justify-between p-2 border-b-2 border-black font-semibold">
          <h3>Shopping Cart</h3>
          <div className="cursor-pointer" onClick={handleCloseClick}>
            <AiOutlineClose />
          </div>
        </div>
        <div className="h-full overflow-y-scroll hideScrollbar">
          <div className="h-max text-sm sm:text-base lg:text-lg">
            {isCartEmpty && (
              <div className="flex flex-col items-center gap-2 m-5">
                <BsCartX className="text-5xl" />
                <h4 className="font-semibold">Cart is Empty</h4>
              </div>
            )}
            {!isCartEmpty && (
              <>
                <div>
                  {cart.map((product, idx) => (
                    <CartItem item={product} key={idx} />
                  ))}
                </div>
                <div className="px-2 py-5 font-semibold mb-14">
                  <div className="flex items-center justify-between text-lg">
                    <p className="font-medium">Total:</p>
                    <p>₹ {totalAmount}</p>
                  </div>
                  <div
                    className="text-center bg-blue-500 text-white m-2 rounded cursor-pointer"
                    onClick={handleOrder}
                  >
                    Order Now!
                  </div>
                </div>
              </>
            )}
          </div>
          <ConfirmationModal
            show={showModal}
            onClose={handleCloseModal}
            onConfirm={handleConfirm}
            message="Please update your phone number!"
            action="Update"
          />
          
        </div>
      </div>
    </div>
  );
}

export default Cart;
