import React, { useState } from "react";
import { GrEdit } from "react-icons/gr";
import { MdOutlineDelete } from "react-icons/md";
import { axiosClient } from "../../utils/axiosClient";
import toast from "react-hot-toast";
import Tooltip from "../Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { getShopAdmin } from "../../redux/slices/shopsSlice";

function Facilities({ facility, featuresId }) {
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [title, setTitle] = useState(facility.title);
  const dispatch = useDispatch();
  const shopId = useSelector((state) => state.shopsSlice.shopId);

  const deleteFeature = async (featureId, imgId) => {
    try {
      const response = await axiosClient.delete(
        `deleteShop/features/${shopId}/${featuresId}/${featureId}?imgId=${imgId}`
      );
      toast.success(response.result);
      dispatch(getShopAdmin(shopId));
    } catch (error) {
      return;
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await axiosClient.put(
        `updateShop/feature/${shopId}/${featuresId}/${facility._id}`,
        { title }
      );
      toast.success(response.result);
      setToggleUpdate(false);
      dispatch(getShopAdmin(shopId));
    } catch (error) {
      setToggleUpdate(false);
    }
  };

  return (
    <div className="hover:shadow-lg transition-transform transform hover:scale-105">
      <div className="font-medium p-2 sm:p-3 bg-white rounded-lg text-center ">
        <div className="flex justify-between items-center mb-2 text-base sm:text-lg text-blue-900">
          <Tooltip tooltipText="Edit">
            <GrEdit onClick={() => setToggleUpdate(true)} />
          </Tooltip>
          <Tooltip tooltipText="Delete">
            <MdOutlineDelete
              onClick={() =>
                deleteFeature(facility._id, facility.imgUrl.publicId)
              }
            />
          </Tooltip>
        </div>
        <div>
          <img
            src={facility.imgUrl.url}
            alt="img"
            className="size-40 sm:size-52 rounded-md"
          />
          {toggleUpdate ? (
            <div className="flex flex-col gap-2 sm:gap-3">
              <input
                type="text"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="border border-blue-900 rounded text-xs p-1 sm:text-sm mt-2 sm:mt-3"
              />
              <button onClick={handleUpdate}>Submit</button>
            </div>
          ) : (
            <p className="text-xs sm:text-sm mt-2 sm:mt-3">{facility.title}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Facilities;
