import React, { useEffect, useState } from "react";
import { axiosClient } from "../utils/axiosClient";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

function PaymentPage() {
  const params = useParams();
  const navigate = useNavigate();
  const [history, setHistory] = useState([]);
  const [paymentHistory, setPaymentHistory] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [subscription, setSubscription] = useState(false);
  const [nextDueDate, setNextDueDate] = useState("");
  const [load, setLoad] = useState(false);

  const initPay = (data) => {
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: data.amount,
      currency: data.currency,
      description: "Pay Rs. 19 to restart the service.",
      order_id: data.id,
      handler: async (response) => {
        try {
          const verificationResponse = await axiosClient.post(
            `payment/verify-payment/${params.id}`,
            response
          );

          if (verificationResponse.status === "ok") {
            toast.success(verificationResponse.result);
            navigate(`/shop-admin/${params.id}`);
          } else {
            toast.error(verificationResponse.result);
          }
        } catch (e) {
          return;
        } finally {
          setIsProcessing(false);
        }
      },
      them: {
        color: "#3399cc",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const handlePay = async () => {
    try {
      setIsProcessing(true);
      const data = await axiosClient.post(`payment/create-order/${params.id}`);
      initPay(data.result);
    } catch (error) {
      setIsProcessing(false);
      return;
    }
  };

  useEffect(() => {
    const getHistory = async () => {
      try {
        setLoad(true);
        const response = await axiosClient.get(`payment/${params.id}`);
        setHistory(response.result);

        const successfulPayments = response?.result?.filter(
          (payment) => payment.status === "success"
        );

        if (successfulPayments.length > 0) {
          const latestPayment =
            successfulPayments[successfulPayments.length - 1];
          setNextDueDate(latestPayment.nextDueDate);

          const today = new Date();
          const paymentDate = new Date(latestPayment.paymentDate);
          const dueDate = new Date(latestPayment.nextDueDate);

          setSubscription(today >= paymentDate && today <= dueDate);
        } else {
          setSubscription(false);
        }
      } catch (e) {
        return;
      } finally {
        setLoad(false);
      }
    };

    getHistory();
  }, []);

  const formatDate = (data) => {
    const date = new Date(data);
    return date.toLocaleDateString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  return (
    <>
      <div className="flex justify-center items-center pt-5">
        <div className="flex items-center border-2 border-black bg-blue-900 text-white w-full mx-4 sm:mx-16 md:mx-28 lg:mx-48 text-sm sm:text-base">
          <p
            className={`border-r-2 border-black w-full text-center px-5 py-1 cursor-pointer ${
              paymentHistory ? "opacity-100" : "opacity-80"
            }`}
            onClick={() => setPaymentHistory(true)}
          >
            Payment History
          </p>
          <p
            className={`w-full text-center px-5 py-1  cursor-pointer ${
              !paymentHistory ? "opacity-100" : "opacity-80"
            }`}
            onClick={() => setPaymentHistory(false)}
          >
            Make Payment
          </p>
        </div>
      </div>
      {paymentHistory && (
        <div className="mt-5 flex justify-center h-[70vh]">
          <div className="w-full mx-4 sm:mx-16 md:mx-28 lg:mx-48">
            <div className="overflow-x-auto">
              <table className="border-collapse w-full">
                <thead>
                  <tr className="flex">
                    <th className="p-1 bg-blue-900 text-white flex-1">
                      Payment Date
                    </th>
                    <th className="p-1 bg-blue-900 text-white flex-1">
                      Amount Paid
                    </th>
                    <th className="p-1 bg-blue-900 text-white flex-1">
                      Status
                    </th>
                    <th className="p-1 bg-blue-900 text-white flex-1">
                      Next Payment
                    </th>
                  </tr>
                </thead>
                <tbody className="block h-32 overflow-y-auto hideScrollbar">
                  {!load &&
                    history?.map((data, idx) => {
                      return (
                        <tr
                          key={idx}
                          className="flex items-center text-center w-full border border-blue-900"
                        >
                          <td className="p-1 flex-1">
                            {formatDate(data.paymentDate)}
                          </td>
                          <td className="p-1 flex-1">₹ {data.amount}</td>
                          <td
                            className={`p-1 flex-1 ${
                              data.status === "success"
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
                          >
                            {data.status}
                          </td>
                          <td className="p-1 flex-1">
                            {formatDate(data.nextDueDate)}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {!paymentHistory && (
        <div className="flex justify-center items-center text-center h-[70vh]">
          <div className="flex flex-col gap-5 justify-center">
            {subscription ? (
              <div>
                <p>
                  <strong>Subscription Active</strong>
                </p>
                <p>
                  Your subscription is active. Thank you for renewing! You have
                  access to all features until {formatDate(nextDueDate)}.
                </p>
              </div>
            ) : (
              <div>
                <p>
                  <strong>Subscription Expired</strong>
                </p>
                <p>
                  Your monthly subscription has expired. Please renew for only
                  ₹19 to continue accessing all features until.
                </p>

                <button
                  onClick={handlePay}
                  disabled={isProcessing}
                  className={`${
                    isProcessing ? "bg-red-500" : "bg-green-500"
                  } border border-black rounded p-1  text-white mx-10`}
                >
                  {isProcessing ? "Processing..." : "Renew Now for ₹ 19"}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default PaymentPage;
