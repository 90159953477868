import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosClient } from "../utils/axiosClient";
import { BiSolidCameraPlus } from "react-icons/bi";
import { MdOutlineNavigateNext } from "react-icons/md";
import toast from "react-hot-toast";

function CreateShopProducts() {
  const [product, setProduct] = useState("");
  const [products, setProducts] = useState([]);
  const [productImg, setProductImg] = useState("");
  const [productName, setProductName] = useState("");
  const [productDetail, setProductDetail] = useState("");
  const [price, setPrice] = useState("");

  const params = useParams();
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      if (fileReader.readyState === fileReader.DONE) {
        setProductImg(fileReader.result);
      }
    };
  };

  const submitShopProductss = async () => {
    try {
      const response = await axiosClient.post(`shop/products/${params.id}`, {
        product,
        products,
      });
      toast.success(response.result);
      setProduct("");
      setProducts([]);
    } catch (error) {
      toast.error(error);
    }
  };

  const addProducts = () => {
    if (productName && productImg && productDetail && price) {
      const numPrice = parseInt(price);
      setProducts([
        ...products,
        {
          name: productName,
          img: productImg,
          detail: productDetail,
          price: numPrice,
        },
      ]);
      setProductImg("");
      setProductName("");
      setProductDetail("");
      setPrice(0);
    } else {
      toast.error("Please fill in all the details.");
    }
  };

  const imgLabel =
    "flex justify-center items-center border-2 rounded-xl w-48 h-48 text-blue-900 text-2xl bg-blue-100 border-blue-900 border-dashed";
  const input = "border-2 border-blue-900 rounded-lg px-2 text-blue-500 w-48";
  const submit =
    "bg-blue-900 rounded-full px-2 text-white cursor-pointer font-semibold w-48 sm:mt-0 hover:bg-blue-800 hover:text-gray-100";

  return (
    <div>
      <p className="text-center text-blue-900 font-bold text-xl">
        Enter Products
      </p>
      <div className="flex justify-center ">
        <div className="my-5">
          <input
            type="text"
            className={`${input}`}
            placeholder="Product"
            value={product}
            onChange={(e) => setProduct(e.target.value)}
          />
          <div className="my-1">
            {productImg ? (
              <div>
                <img
                  src={productImg}
                  alt="userImg"
                  className="border-2 border-blue-900 border-dashed rounded-xl w-48 h-48"
                />
              </div>
            ) : (
              <div className={`${imgLabel}`}>
                <label htmlFor="inputImg">
                  <BiSolidCameraPlus className="bg-blue-100 cursor-pointer" />
                </label>
                <input
                  className={`${input} hidden`}
                  id="inputImg"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
            )}
          </div>

          <div>
            <input
              type="text"
              placeholder="Product Name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              className={`${input} mb-1`}
            />
          </div>
          <div>
            <textarea
              placeholder="Enter product detail (Limit: 50 words)"
              value={productDetail}
              onChange={(e) => setProductDetail(e.target.value)}
              className={`${input} mb-1 h-48`}
              maxLength="50"
            />
          </div>
          <div className={`${input} mb-1 flex gap-2`}>
            <p className="text-blue-900">₹: </p>
            <input
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="w-36 outline-none"
            />
          </div>

          <button onClick={addProducts} className={`${submit} my-1`}>
            Add Product
          </button>
          <div
            onClick={() => {
              navigate(`/shop-admin/${params.id}`);
            }}
          >
            <MdOutlineNavigateNext className={`${submit} text-2xl`} />
          </div>
        </div>
      </div>

      {products.length > 0 && (
        <div>
          <p className="text-center bg-blue-900 text-white font-semibold py-1 text-lg">
            {product}
          </p>
          {/* CARD SECTION */}
          <div className="overflow-x-auto hideScrollbar">
            <div className="flex gap-4 w-max">
              {products.map((data, idx) => (
                <div
                  key={idx}
                  className="p-3 bg-white rounded-xl text-blue-900 my-5 w-56"
                >
                  <img src={data.img} alt="img" className="w-52 h-52 rounded" />
                  <div className="bg-white font-semibold flex justify-between">
                    <p className="bg-white">{data.name}</p>
                    <p className="bg-white">₹ {data.price}</p>
                  </div>
                  <p className="bg-white text-sm">{data.detail}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-center">
            <button
              onClick={submitShopProductss}
              className={`${submit} w-48 mb-5`}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateShopProducts;
