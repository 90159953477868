import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiSolidCameraPlus } from "react-icons/bi";
import { axiosClient } from "../utils/axiosClient";
import toast from "react-hot-toast";
import { getMyInfo } from "../redux/slices/appConfigSlice";

function UpdateProfile() {
  const [userImg, setUserImg] = useState("");
  const [curUserImg, setCurUserImg] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const dispatch = useDispatch();

  const myProfile = useSelector((state) => state?.appConfigReducer?.myProfile);

  useEffect(() => {
    if (myProfile) {
      setCurUserImg(myProfile?.imgUrl?.url);
      setName(myProfile?.name);
      setPhone(myProfile?.phone);
    }
  }, [myProfile]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      if (fileReader.readyState === fileReader.DONE) {
        setUserImg(fileReader.result);
      }
    };
    fileReader.onerror = () => {
      toast.error("Error reading file");
    };
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosClient.put("user/updateProfile", {
        name,
        userImg,
        phone,
      });

      toast.success(response.result);
      setUserImg("");
      dispatch(getMyInfo());
    } catch (error) {
      setUserImg("");
      return;
    }
  };

  return (
    <div className="flex justify-center items-center sm:items-start bg-white -mt-2 p-5 sm:py-5 h-[80vh]">
      <div className="flex flex-col items-center gap-3 p-5 rounded sm:rounded-lg border border-blue-900 h-max">
        <p className="text-lg sm:text-2xl font-medium text-center">
          Update Profile
        </p>
        <div className="sm:flex items-center gap-10 text-xs sm:text-sm">
          <div className="flex-1 mb-3 sm:mb-0">
            <div className="flex justify-center">
              {userImg ? (
                <img
                  src={userImg}
                  alt="Profile"
                  className="border-2 border-blue-900 border-dashed rounded-full size-32 sm:size-52"
                />
              ) : (
                <img
                  src={curUserImg}
                  alt="Profile"
                  className="border-2 border-blue-900 border-dashed rounded-full size-32 sm:size-52"
                />
              )}
            </div>
            <div className="mt-3">
              <label htmlFor="inputImg">
                <p className="flex justify-center cursor-pointer border rounded border-blue-900 bg-blue-500 text-white text-xl px-2 py-[0.5px] w-10">
                  <BiSolidCameraPlus />
                </p>
              </label>
              <input
                id="inputImg"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleImageChange}
              />
            </div>
          </div>

          <div className="flex-1 flex flex-col gap-3">
            <label htmlFor="name" className="font-medium text-base">
              Name
            </label>
            <input
              id="name"
              type="text"
              value={name}
              className="px-5 py-1 border border-blue-900 rounded focus:outline-none"
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
            />

            <label htmlFor="phone" className="font-medium text-base">
              Phone
            </label>
            <input
              id="phone"
              type="tel"
              value={phone}
              className="px-5 py-1 border border-blue-900 rounded focus:outline-none"
              placeholder="Phone"
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>
        <button
          onClick={handleUpdate}
          className="px-5 py-1 w-full text-white border border-blue-900 bg-blue-500 rounded focus:outline-none cursor-pointer"
        >
          Update
        </button>
      </div>
    </div>
  );
}

export default UpdateProfile;
