import axios from "axios";

export const getDistance = async (origin, destination) => {
  const apiKey = process.env.REACT_APP_DISTANCE_API_KEY;
  try {
    const response = await axios.get(
      `https://api.openrouteservice.org/v2/directions/driving-car?api_key=${apiKey}&start=${origin.lng},${origin.lat}&end=${destination.lng},${destination.lat}`
    );

    const distance = response.data.features[0].properties.summary.distance; // distance in meters
    return distance / 1000; // convert to kilometers
  } catch (error) {
    return 0.0;
  }
};
