import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PiMaskSad } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { getShopsCategory } from "../redux/slices/shopsSlice";

function AllShops() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const shopsCategoryData = useSelector(
    (state) => state.shopsSlice.categoriesData
  );
  const isLoading = useSelector((state) => state.appConfigReducer.isLoading);

  useEffect(() => {
    dispatch(getShopsCategory());
  }, [dispatch]);

  return (
    <div className="bg-slate-200">
      <p className="text-lg sm:text-2xl font-bold py-1 bg-blue-900 text-white text-center">
        Shop Varieties
      </p>
      <div className="flex items-center justify-center">
        {shopsCategoryData?.length === 0 ? (
          !isLoading && (
            <div className="flex flex-col gap-5 mt-2 mb-5 text-blue-900">
              <p className="flex justify-center text-8xl">
                <PiMaskSad />
              </p>
              <p className="text-center font-semibold">
                No Shops Variety Exist!
              </p>
            </div>
          )
        ) : (
          <div className="grid grid-cols-2 mx-2 md:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-5 max-w-screen-lg py-10">
            {shopsCategoryData?.map((data, idx) => (
              <div
                className=" p-2 sm:p-3 bg-white text-center rounded-lg cursor-pointer shadow-lg overflow-hidden transition-transform transform hover:scale-105"
                onClick={() => navigate(`/shop-list/${data._id}`)}
                key={idx}
              >
                <img
                  src={data.imgUrl.url}
                  alt="imgs"
                  className="rounded-md size-36 sm:size-48"
                />
                <p className="font-semibold text-xs sm:text-base mt-1">
                  {data?.categoryName}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default AllShops;
