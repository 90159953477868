import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoLocationOutline, IoTimeOutline } from "react-icons/io5";
import { LiaCalendarWeekSolid } from "react-icons/lia";
import { axiosClient } from "../utils/axiosClient";
import { PiMaskSad } from "react-icons/pi";
import toast from "react-hot-toast";
import DistanceCalculator from "../components/DistanceCalculator";
import { isShopOpen } from "../utils/shopStatus";
import { useSelector } from "react-redux";

function AllShops() {
  const [allShops, setAllShops] = useState([]);
  const [filteredShops, setFilteredShops] = useState([]);
  const navigate = useNavigate();
  const [location, setLocation] = useState({ lat: null, lng: null });
  const [availabilityFilter, setAvailabilityFilter] = useState("all");
  const [maxDistance, setMaxDistance] = useState(5);
  const params = useParams();
  const isLoading = useSelector((state) => state.appConfigReducer.isLoading);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (err) => {
          toast.error("Unable to retrieve your location.");
        },
        {
          enableHighAccuracy: true,
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    fetchCategoryShops();
  }, [location.lat, location.lng, maxDistance]);

  const fetchCategoryShops = async () => {
    try {
      if (!location?.lat || !location?.lng) {
        return;
      }
      const response = await axiosClient.get(
        `category/allCategoryShops?lat=${location?.lat}&lng=${location?.lng}&maxDistance=${maxDistance}`
      );

      setAllShops(response.result[params.id].shopsList);
      setFilteredShops(response.result[params.id].shopsList);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    applyFilteres();
  }, [availabilityFilter, allShops]);

  const applyFilteres = () => {
    let filtered = allShops;

    if (availabilityFilter === "open") {
      filtered = filtered.filter((shop) =>
        isShopOpen(shop?.operatingDays, shop?.operatingHours)
      );
    } else if (availabilityFilter === "closed") {
      filtered = filtered.filter(
        (shop) => !isShopOpen(shop?.operatingDays, shop?.operatingHours)
      );
    }

    filtered.sort((a, b) => {
      const isAOpen = isShopOpen(a?.operatingDays, a?.operatingHours);
      const isBOpen = isShopOpen(b?.operatingDays, b?.operatingHours);
      return isAOpen === isBOpen ? 0 : isAOpen ? -1 : 1;
    });

    setFilteredShops(filtered);
  };

  return (
    <>
      <p className="text-center text-2xl bg-blue-900 text-white py-1 font-bold">
        {params.id === "1" ? "Barber Shops" : "Grocery Shops"}
      </p>

      {/* FILTER */}
      <div className="flex justify-start">
        <div className="flex items-center gap-20 m-4 sm:mt-10 sm:ml-32">
          {/* FILTER BASED ON DISTANCE */}
          <select
            name="Distance"
            onChange={(e) => setMaxDistance(e.target.value)}
            className="rounded p-1 outline-none border border-black"
          >
            <option value="5">Within 5 Km</option>
            <option value="10">Within 10 Km</option>
            <option value="15">Within 15 Km</option>
            <option value="20">Within 20 Km</option>
          </select>

          {/* FILTER BASED ON SHOP AVAILABILITY */}
          <select
            name="Availability"
            onChange={(e) => setAvailabilityFilter(e.target.value)}
            className="rounded p-1 outline-none border border-black"
          >
            <option value="all">All</option>
            <option value="open">Open</option>
            <option value="closed">Closed</option>
          </select>
        </div>
      </div>
      <div className="flex justify-center">
        {filteredShops.length !== 0 ? (
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 sm:gap-6 max-w-screen-lg m-4 sm:mb-10">
            {filteredShops?.map((shop, idx) => (
              <div
                key={idx}
                className="p-2 sm:p-3 rounded-xl bg-white cursor-pointer flex flex-col items-center gap-2 hover:shadow-lg overflow-hidden transition-transform transform hover:scale-105"
                onClick={() => navigate(`/shop-detail/${shop.shopId}`)}
              >
                <img
                  src={shop?.shopImg}
                  alt={shop?.shopName}
                  className="h-44 sm:h-52 w-full rounded-lg"
                />
                <p className="font-semibold text-center">{shop?.shopName}</p>

                <div className="flex justify-between w-full items-center text-xs">
                  {location && (
                    <DistanceCalculator
                      origin={location}
                      destinationCoords={shop?.location?.coordinates}
                    />
                  )}
                  <p
                    className={`font-medium  rounded p-[2px] text-white ${
                      isShopOpen(shop?.operatingDays, shop?.operatingHours)
                        ? "bg-green-500"
                        : "bg-red-500"
                    }`}
                  >
                    {isShopOpen(shop?.operatingDays, shop?.operatingHours)
                      ? "Open"
                      : "Closed"}
                  </p>
                </div>

                <div>
                  <div className="flex gap-1 sm:gap-2 text-xs">
                    <IoLocationOutline />
                    <p className="font-medium">{shop?.shopAddress}</p>
                  </div>

                  <div className="flex items-center gap-1 sm:gap-2 text-xs ">
                    <LiaCalendarWeekSolid />
                    <p className="font-medium">
                      {shop?.operatingDays?.join(", ")}
                    </p>
                  </div>
                  <div className="flex items-center gap-1 sm:gap-2 text-xs">
                    <IoTimeOutline />
                    <p className="font-medium">{shop?.operatingHours}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          !isLoading && (
            <div className="flex flex-col gap-5 mt-2 mb-5 text-blue-900 h-96">
              <p className="flex justify-center text-8xl">
                <PiMaskSad />
              </p>
              <p className="text-center font-semibold">No Shop Exist!</p>
            </div>
          )
        )}
      </div>
    </>
  );
}

export default AllShops;
