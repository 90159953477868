import toast from "react-hot-toast";
import { axiosClient } from "./utils/axiosClient";

async function regSw() {
  if ("serviceWorker" in navigator) {
    let url = "/sw.js";

    const reg = await navigator.serviceWorker.register(url, { scope: "/" });
    return reg;
  }
  throw Error("serviceworker not supported");
}

async function subscribe(serviceWorkerReg) {
  let subscription = await serviceWorkerReg.pushManager.getSubscription();

  if (subscription === null) {
    subscription = await serviceWorkerReg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: process.env.REACT_APP_APPLICATION_SERVER_KEY,
    });

    try {
      await axiosClient.post("user/subscribe", subscription);
    } catch (e) {
      toast.error(e);
    }
  }
}

export { regSw, subscribe };
