import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../redux/slices/cartSlice";

function Products({ product, shopId }) {
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  const productId = product._id;
  const type = useSelector((state) => state.shopsSlice.shopsData).type;

  return (
    <div className="hover:shadow-lg overflow-hidden transition-transform transform hover:scale-105">
      <div className="p-2 sm:p-3 bg-white rounded-lg text-xs sm:text-sm">
        <img
          src={product.imgUrl.url}
          alt="img"
          className="size-40 sm:size-52 rounded-md"
        />
        <p className="text-center font-medium my-1">{product.name}</p>
        <div className="flex justify-between">
          <p>
            ₹{" "}
            <span className="font-medium sm:font-semibold">
              {product.price}
            </span>
          </p>
          {type === "order" && (
            <div className="flex items-center gap-1">
              <p>Qty</p>
              <select
                name="quantity"
                id="quantity"
                value={quantity}
                className="font-medium sm:font-semibold cursor-pointer focus:outline-none bg-white"
                onChange={(e) => setQuantity(parseInt(e.target.value))}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          )}
        </div>
        <p className="my-1 h-5 w-40 sm:w-52 text-xs text-gray-800">
          {product.detail}
        </p>
        {type === "order" && (
          <div className="flex justify-center">
            <button
              className="border-2 rounded-full px-4 text-blue-900 border-blue-900 font-semibold cursor-pointer"
              onClick={() =>
                dispatch(addToCart({ productId, quantity, shopId }))
              }
            >
              Add To Cart
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Products;
