import React, { useState } from "react";
import { axiosClient } from "../utils/axiosClient";
import toast from "react-hot-toast";
import { getMyInfo } from "../redux/slices/appConfigSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function ShopPayment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);

  const savedShopData = JSON.parse(localStorage.getItem("shopData"));

  const initPay = (data) => {
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: data.amount,
      currency: data.currency,
      name: savedShopData.shopName,
      description: "Pay ₹ 2 for shop registration",
      image: savedShopData.shopImg,
      order_id: data.id,
      handler: async (response) => {
        try {
          const verificationResponse = await axiosClient.post(
            "payment/verify",
            response
          );

          if (verificationResponse.status === "ok") {
            toast.success(verificationResponse.result);
            setIsProcessing(true);
            const shopResponse = await axiosClient.post("shop/", {
              shopImg: savedShopData.shopImg,
              shopName: savedShopData.shopName,
              shopDetail: savedShopData.shopDetail,
              type: savedShopData.type,
              shopAddress: savedShopData.shopAddress,
              phone: savedShopData.phone,
              email: savedShopData.email,
              operatingDays: savedShopData.operatingDays,
              fromTime: savedShopData.fromTime,
              toTime: savedShopData.toTime,
              category: savedShopData.category,
              lat: savedShopData.lat,
              lng: savedShopData.lng,
              paymentId: response.razorpay_payment_id,
              orderId: response.razorpay_order_id,
              signature: response.razorpay_signature,
            });
            toast.success(shopResponse.result.message);
            dispatch(getMyInfo());
            localStorage.removeItem("shopData");
            navigate(`/create-shop-features/${shopResponse.result.id}`);
          } else {
            toast.error(verificationResponse.result);
          }
        } catch (e) {
          setIsProcessing(false);
          return;
        }
      },
      them: {
        color: "#3399cc",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const handlePay = async () => {
    try {
      setIsProcessing(true);
      const data = await axiosClient.post("payment/orders", {
        amount: 2,
      });
      initPay(data.result);
    } catch (error) {
      setIsProcessing(false);
      return;
    }
  };

  return (
    <div className="flex justify-center items-center h-[70vh]">
      <div className="flex flex-col gap-5 justify-center">
        <p>
          Note: You just need to pay ₹ 2 to register your shop.
        </p>
        <button
          onClick={handlePay}
          disabled={isProcessing}
          className={`${
            isProcessing ? "bg-red-500" : "bg-green-500"
          } border border-black rounded p-1  text-white mx-10`}
        >
          {isProcessing ? "Processing..." : "Make Payment"}
        </button>
      </div>
    </div>
  );
}

export default ShopPayment;
